import { FIND_EMPLOYEE, FIND_EMPLOYEES } from '../types'

const initialState = {
  employee: [],
  employees: [],
  pages: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_EMPLOYEES:
      return {
        ...state,
        employees: action.data.employees.entities,
        pages: action.pages,
      }
    case FIND_EMPLOYEE:
      return {
        ...state,
        employee: action.data.employee,
      }
    // case Update_EMPLOYEE:

    default:
      return state
  }
}
