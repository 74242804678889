import './App.css'

import React from 'react'

import Container from './components/container'
import Snackbar from './components/snackbar'

function App() {
  return (
    <div className="App">
      <Container />
      <Snackbar />
    </div>
  )
}

export default App
