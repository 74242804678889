import axios from 'axios'

import history from '../../history'
import { ADD_SNACKBAR } from '../types'

// const { enqueueSnackbar } = useSnackbar();

export const action =
  (type, url, modelName, link) => (body) => async (dispatch) => {
    try {
      const { data, pages } = (await axios.post(url, body)).data
      dispatch({ type, data: { [`${modelName}`]: data }, pages })
      // enqueueSnackbar('This is a success message!', {
      //   variant: 'success'
      // });
      if (link && data[link.objectName]) {
        const objectValue = data[link.objectName]
        history.push(`${link.to}/${objectValue}`)
      }
      return {
        data,
        statusCode: '200',
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: 'LOGOUT_USER' })
        dispatch({
          type: 'ADD_SNACKBAR',
          snackbar: {
            variant: 'error',
            message: `Sorry, your session is expired, you can login now.`,
          },
        })
      }
      return {
        response: error.response,
        data: error.response?.data,
        statusCode: error.response?.status,
      }
    }
  }

export const loginAction =
  (type, url, modelName) => (body) => async (dispatch) => {
    try {
      const { data } = (await axios.post(url, body)).data
      const { token } = data
      delete data.token
      axios.defaults.headers.Authorization = `Bearer ${token}`
      dispatch([
        {
          type,
          data: { [`${modelName}`]: data.user, token, isLoggedIn: true },
        },
        {
          type: ADD_SNACKBAR,
          snackbar: {
            variant: 'success',
            message: `Welcome ${data.user.name.firstName}!`,
          },
        },
      ])
      return {
        data,
        statusCode: '200',
      }
    } catch (error) {
      // console.error(error)
      dispatch({
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'error',
          message: 'ERROR',
        },
      })
      return {
        response: error.response,
        data: error.response?.data,
        statusCode: error.response?.status,
      }
    }
  }

export const registerAction = () => (body, token) => async (dispatch) => {
  try {
    const { data } = (
      await axios.post(`/user/employee/register/${token}`, body)
    ).data
    dispatch([
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: "You've been registered successfully!",
        },
      },
    ])
    history.push('/home')
    return {
      data,
      statusCode: '200',
    }
  } catch (error) {
    dispatch({
      type: ADD_SNACKBAR,
      snackbar: {
        variant: 'error',
        message: 'A problem occured',
      },
    })
    return {
      response: error.response,
      data: error.response?.data,
      statusCode: error.response?.status,
    }
  }
}

export const actionToken =
  (type, url, modelName, link) => (body) => async (dispatch) => {
    try {
      const token = body.urlToken || ''
      delete body.urlToken
      const { data, pages } = (await axios.post(`${url}/${token}`, body)).data
      dispatch({ type, data: { [`${modelName}`]: data }, pages })
      if (link && data[link.objectName]) {
        const objectValue = data[link.objectName]
        history.push(`${link.to}/${objectValue}`)
      }
      return {
        data,
        statusCode: '200',
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: 'LOGOUT_USER' })
        dispatch({
          type: 'ADD_SNACKBAR',
          snackbar: {
            variant: 'error',
            message: `Sorry, your session is expired, you can login now.`,
          },
        })
      }
      return {
        response: error.response,
        data: error.response?.data,
        statusCode: error.response?.status,
      }
    }
  }

export const actionGetToken =
  (type, url, modelName, link) => (body) => async (dispatch) => {
    try {
      const token = body.urlToken || ''
      delete body.urlToken
      const { data, pages } = (await axios.get(`${url}/${token}`)).data
      dispatch({ type, data: { [`${modelName}`]: data }, pages })
      if (link && data[link.objectName]) {
        const objectValue = data[link.objectName]
        history.push(`${link.to}/${objectValue}`)
      }
      return {
        data,
        statusCode: '200',
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: 'LOGOUT_USER' })
        dispatch({
          type: 'ADD_SNACKBAR',
          snackbar: {
            variant: 'error',
            message: `Sorry, your session is expired, you can login now.`,
          },
        })
      }
      return {
        response: error.response,
        data: error.response?.data,
        statusCode: error.response?.status,
      }
    }
  }

/**
 * actions that don't affect the store
 * might still dispatch snackbars
 */
export const noDisaptchAction =
  (url, withNotifications, successMessageFunction, errorMessageFunction) =>
  (body) =>
  async (dispatch) => {
    try {
      const { data } = (await axios.post(url, body)).data
      if (withNotifications) {
        dispatch([
          {
            type: ADD_SNACKBAR,
            snackbar: {
              variant: 'success',
              message: successMessageFunction(data),
            },
          },
        ])
        return {
          data,
          statusCode: '200',
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: 'LOGOUT_USER' })
        dispatch({
          type: 'ADD_SNACKBAR',
          snackbar: {
            variant: 'error',
            message: `Sorry, your session is expired, you can login now.`,
          },
        })
      }
      if (withNotifications) {
        dispatch({
          type: ADD_SNACKBAR,
          snackbar: {
            variant: 'error',
            message: errorMessageFunction(error),
          },
        })
      }
      return {
        response: error.response,
        data: error.response?.data,
        statusCode: error.response?.status,
      }
    }
  }
