import { FIND_GUEST, FIND_GUESTS, UPDATE_GUEST_ALLOWED_ENTRIES } from '../types'

const initialState = {
  guest: [],
  guests: [],
  pages: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_GUESTS:
      return {
        ...state,
        guests: action.data.guests.entities,
        pages: action.data.guests.pages,
      }
    case FIND_GUEST:
      return {
        ...state,
        guest: action.data.guest,
      }
    case UPDATE_GUEST_ALLOWED_ENTRIES:
      return {
        ...state,
        guest: action.data.guest,
      }
    default:
      return state
  }
}
