/**
 * This file is implemented to show orders single component
 *
 * @author Ahmed Samir Morsy
 */

import React from 'react'
import { connect } from 'react-redux'

import { updateUser } from '../../store/actions'
import Main from './main'

const Profile = (props) => {
  React.useEffect(() => {}, [])

  return (
    <Main
      data={props.user}
      notToShow={['password', 'emailConfirmed', 'deleted', 'userType']}
    />
  )
}

const mapStateToProps = (store) => {
  return {
    user: store.userReducer.user,
  }
}

const mapDispatchToProps = {
  updateUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
