import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'

const ChipRender = (props) => {
  const [open, setOpen] = React.useState(-1)

  const handleClose = () => {
    setOpen(-1)
  }

  const handleOpen = (i) => {
    setOpen(i)
  }

  return (
    <>
      {Object.keys(props.data).map((data, i) => (
        <>
          {typeof props.data[data] !== 'object' ? (
            <Tooltip
              open={open === i}
              onClose={() => handleClose()}
              onOpen={() => handleOpen(i)}
              title={props.data[data]}
              interactive
              placement="top"
            >
              <Chip
                key={i}
                label={`${props.data[data]}`}
                className="chip"
                style={{
                  maxWidth: '100px',
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </>
      ))}
    </>
  )

  //   const boolean = getNestedObject(rowData, path)
  //   return <p>{boolean ? 'Yes' : 'No'}</p>
}

export default ChipRender
