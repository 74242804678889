import axios from 'axios'
import React, { useState } from 'react'

const UploadExcel = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [filesNames, setFilesNames] = useState([])
  const handleFiles = (event) => {
    const { files } = event.target
    const fileNamesTemp = []
    for (let i = 0; i < Object.keys(files).length; i++) {
      const file = files[i]
      const { name } = file
      const splittedName = name.split('.')
      const fileName = splittedName[splittedName.length - 1]
      fileNamesTemp.push(fileName)
    }
    setFilesNames(fileNamesTemp)
    setUploadedFiles(files)
  }

  const handleFilesUpload = async () => {
    const file = uploadedFiles[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('event', props.id)
    axios
      .post('/guest/createFromExcel', formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(() => {
        axios.post('/guest/readAll', {
          limit: 10,
          page: 1,
          sort: '-createdAt',
          _id: props.id,
        })
      })
      .catch(() => {})
  }

  return (
    <div className="upload-form">
      <input type="file" onChange={handleFiles} />
      <div className="submitHolder">
        <button type="submit" onClick={handleFilesUpload}>
          Upload
        </button>
      </div>
    </div>
  )
}

export default UploadExcel
