import React from 'react'
import { connect } from 'react-redux'

import { register } from '../store/actions'
import AbstractDialog from './dialog/abstractDialog'

const Register = (props) => {
  return (
    <AbstractDialog
      open
      title="Register"
      info="Please enter your info to complete your registration"
      inputs={[
        {
          name: 'title',
          label: 'Title',
          type: 'select',
          required: true,
          options: [
            {
              label: 'Mr',
              value: 'Mr',
            },
            {
              label: 'Mrs',
              value: 'Mrs',
            },
            {
              label: 'Ms',
              value: 'Ms',
            },
          ],
        },
        {
          name: 'firstName',
          label: 'First Name',
          type: 'text',
          required: true,
        },
        {
          name: 'lastName',
          label: 'Last Name',
          type: 'text',
          required: true,
        },
        {
          name: 'mobile',
          label: 'Phone Number',
          type: 'phoneNumber',
          required: true,
        },
        // {
        //   name: 'secondaryPhoneNumber',
        //   label: 'Secondary Phone Number',
        //   type: 'phoneNumber',
        //   required: false
        // },
        // {
        //   name: 'birthDate',
        //   label: 'Birth Date',
        //   type: 'date',
        //   required: true
        // },
        // {
        //   name: 'gender',
        //   label: 'Gender',
        //   type: 'select',
        //   required: true,
        //   options: [
        //     {
        //       label: 'Male',
        //       value: 'male'
        //     },
        //     {
        //       label: 'Female',
        //       value: 'female'
        //     }
        //   ]
        // },
        // {
        //   name: 'nationalId',
        //   label: 'National ID',
        //   type: 'nationalId',
        //   required: true
        // },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true,
        },
      ]}
      onSubmit={(body) => {
        body.name = {
          title: body.title,
          firstName: body.firstName,
          lastName: body.lastName,
        }
        delete body.title
        delete body.firstName
        delete body.lastName
        // body.nationalId = {
        //   value: body.nationalId
        // }
        props.register(body, props.match.params.token)
      }}
    />
  )
}

const mapDispatchToProps = {
  register,
}

export default connect(null, mapDispatchToProps)(Register)
