/**
 * This file is implemented to automate single component
 *
 * @author Ahmed Samir Morsy
 */

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import EditIcon from '@material-ui/icons/Edit'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { NumberParam, StringParam } from 'use-query-params'

import ChipDsiplay from '../util/customRender/chipDsiplay'
import SelectRenderHelper from '../util/customRender/selectRenderHelper'
import ItemPaginate from './addOns/itemPaginate'

const Main = (props) => {
  const [open, setOpen] = React.useState(false)
  const [row, setRow] = React.useState(null)
  const [variantData, setVariantData] = React.useState({})
  const [openVariant, setOpenVariant] = React.useState(false)

  const checkIsToBeShow = (name) => {
    if (
      name.indexOf('_id') === -1 &&
      name !== '__v' &&
      name !== 'updatedAt' &&
      name !== 'tableData' &&
      !props.notToShow?.includes(name) &&
      name.trim() !== ''
    ) {
      return true
    }
    return false
  }

  const checkName = (name) => {
    switch (name) {
      case 'shippingAddress':
        return 'Shipping Address'
      case 'phoneNumber':
        return 'Phone Number'
      case 'lastName':
        return 'Last Name'
      case 'firstName':
        return 'First Name'
      case 'caseMaterial':
        return 'Case Material'
      case 'caseColor':
        return 'Case Color'
      case 'shrinkedImages':
        return 'Shrinked Images'
      case 'solaceHub':
        return 'Solace Hub'
      // case '':
      //     return ''

      default:
        return name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
          return str.toUpperCase()
        })
    }
  }

  const formatDate = (string) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(string).toLocaleDateString([], options)
  }

  const formatTime = (string) => {
    return new Date(string).toLocaleTimeString([])
  }

  const dataToDisplay = () => {
    // return <>{props.data ? Object.keys(props.data).map(
    //     el => <>{ checkIsToBeShow ? <div className={typeof props.data[el] === 'object'? "singleObj fullWidth" :"singleObj"}>
    //         {getDataToShow(el, props.data[el])}</div> : <></>}</>
    // ) : <></>}</>
    if (props.data) {
      const data = { ...props.data }
      const objects = []
      const nonObjects = []
      if (data) {
        Object.keys(data).forEach((el) => {
          if (checkIsToBeShow(el)) {
            if (typeof data[el] === 'object') {
              objects.push({
                name: el,
                data: data[el],
              })
            } else {
              nonObjects.push({
                name: el,
                data: data[el],
              })
            }
          }
        })
        return (
          <>
            {nonObjects.length > 0 ? (
              <div className="fullWidth center">
                <h2>Main Info</h2>
              </div>
            ) : (
              <></>
            )}
            <div className="singleContainer fullWidth">
              {nonObjects.map((el) => (
                <>
                  {el.name === 'description' && props.data.appointmentId ? (
                    getDataToShow(el.name, el.data)
                  ) : (
                    <div className="singleObj">
                      {getDataToShow(el.name, el.data)}
                    </div>
                  )}
                </>
              ))}
            </div>
            {objects.map((el) => (
              <>
                <div className="singleObj fullWidth">
                  {' '}
                  {getDataToShow(el.name, el.data)}
                </div>
              </>
            ))}
          </>
        )
      }
    }
    return <></>
  }

  const getDataToShow = (name, data, type, addClass) => {
    if (checkIsToBeShow(name)) {
      switch (typeof data) {
        case 'object':
          if (Array.isArray(data)) {
            if (
              name.indexOf('img') !== -1 ||
              name.indexOf('Img') !== -1 ||
              name.indexOf('image') !== -1 ||
              name.indexOf('Image') !== -1
            )
              return (
                <>
                  <div className="fullWidth center">
                    <h2>
                      <strong>{`${checkName(name)} `}</strong>
                    </h2>
                  </div>
                  {data.map((el) =>
                    el.url ? (
                      <div className="singleData">
                        <img src={el.url} alt={el.name} />
                      </div>
                    ) : (
                      getDataToShow(name, el)
                    )
                  )}
                </>
              )
            if (
              data.length > 0 &&
              !Array.isArray(data[0]) &&
              typeof data[0] === 'object'
            ) {
              const columns = []
              Object.keys(data[0]).forEach((el) => {
                if (
                  el !== '_id' &&
                  checkIsToBeShow(el) &&
                  typeof data[0][el] !== 'object'
                ) {
                  if (el.indexOf('price') === -1 && el.indexOf('Price') === -1)
                    columns.push({ title: el, field: el })
                  else
                    columns.push({
                      title: el,
                      field: el,
                      type: 'currency',
                      currencySetting: {
                        currencyCode: 'EGP',
                      },
                    })
                } else if (
                  el !== '_id' &&
                  checkIsToBeShow(el) &&
                  typeof data[0][el] === 'object' &&
                  !Array.isArray(data[0][el])
                ) {
                  columns.push({
                    title: el,
                    field: el,
                    render: ChipDsiplay(el),
                  })
                }
              })
              return (
                <ItemPaginate
                  queryParams={{
                    page: NumberParam,
                    search: StringParam,
                  }}
                  title={name}
                  data={data}
                  columns={columns}
                  actions={
                    name === 'variants'
                      ? [
                          {
                            onClick: (event, row) => {
                              setVariantData({ ...row })
                              setOpenVariant(true)
                            },
                            icon: OpenInNewIcon,
                          },
                          {
                            onClick: (event, row) => {
                              // row
                              setRow({ ...row })
                              setOpen(true)
                            },
                            icon: EditIcon,
                          },
                        ]
                      : []
                  }
                />
              )
            }
            if (
              data.length > 0 &&
              !Array.isArray(data[0]) &&
              typeof data[0] !== 'object'
            ) {
              return (
                <>
                  <div className="fullWidth center">
                    <h2>
                      <strong>{`${checkName(name)} `}</strong>
                    </h2>
                  </div>
                  {data.map((el) => (
                    <div className="singleContainer fullWidth">
                      <div className="singleObj fullWidth">
                        <h3 style={{ textTransform: 'capitalize' }}>{el}</h3>
                      </div>
                    </div>
                  ))}
                </>
              )
            }
            return (
              <>
                <div className="fullWidth center">
                  <h2>
                    <strong>{`${checkName(name)} `}</strong>
                  </h2>
                </div>
                {data.map((el) => (
                  <div className="singleContainer fullWidth">
                    {Object.keys(el).map((ele) =>
                      getDataToShow(ele, el[ele], 'obj')
                    )}
                  </div>
                ))}
              </>
            )
          }
          if (
            name.indexOf('img') !== -1 ||
            name.indexOf('Img') !== -1 ||
            name.indexOf('image') !== -1 ||
            name.indexOf('Image') !== -1
          )
            return (
              <>
                {data.url ? (
                  <div className="singleData">
                    <img src={data.url} alt={data.name} />
                  </div>
                ) : (
                  getDataToShow('', data)
                )}
              </>
            )

          return (
            <>
              <div
                className={
                  type !== 'obj' ? 'fullWidth center' : 'fullWidth center'
                }
              >
                <h2>
                  <strong>
                    {name.trim().length > 0 ? `${checkName(name)} ` : ''}
                  </strong>
                </h2>
              </div>
              <div
                className={
                  type !== 'obj'
                    ? 'singleContainer fullWidth'
                    : 'singleContainerNoColor fullWidth'
                }
              >
                {Object.keys(data).length > 1
                  ? Object.keys(data).map((el) =>
                      getDataToShow(el, data[el], 'obj')
                    )
                  : Object.keys(data).map((el) =>
                      getDataToShow(el, data[el], 'obj', 'fullWidth')
                    )}
              </div>
            </>
          )

        default:
          return checkIsToBeShow(name) ? (
            <>
              {name === 'description' && props.data.appointmentId ? (
                <>
                  <div
                    className={`singleData${addClass ? ` ${addClass}` : ''}`}
                  >
                    <p>
                      <strong>{`${checkName(name)} `}</strong>
                    </p>
                    <p>{data.split('Description:')[1]}</p>
                  </div>
                  {getDataToShow(
                    'Type',
                    data.split('Description:')[0].split('Type:')[1]
                  )}
                </>
              ) : (
                <div className={`singleData${addClass ? ` ${addClass}` : ''}`}>
                  {name === 'status' && props.data.orderId ? (
                    <>
                      <p>
                        <strong>{`${checkName(name)} `}</strong>
                      </p>
                      <SelectRenderHelper rowData={props.data} path={name} />
                    </>
                  ) : (
                    <>
                      {name === 'url' ? (
                        <img src={data} alt="" />
                      ) : (
                        <>
                          {name === 'date' || name === 'createdAt' ? (
                            <>
                              <p>
                                <strong>{`${checkName(name)} `}</strong>
                              </p>
                              <p>{formatDate(data)}</p>
                            </>
                          ) : name === 'startDate' || name === 'endDate' ? (
                            <>
                              <p>
                                <strong>{`${checkName(name)} `}</strong>
                              </p>
                              <p>{formatTime(data)}</p>
                            </>
                          ) : (
                            <>
                              <p>
                                <strong>{`${checkName(name)} `}</strong>
                              </p>
                              <p>
                                {name.indexOf('price') === -1 ? (
                                  `${data}`
                                ) : (
                                  <p>
                                    EGP{' '}
                                    <NumberFormat
                                      value={data}
                                      displayType="text"
                                      thousandSeparator
                                    />{' '}
                                  </p>
                                )}
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <></>
          )
      }
    }
  }

  return (
    <div className="singleComponent">
      <Dialog
        open={openVariant}
        onClose={() => setOpenVariant(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        className="dialog"
      >
        <DialogContent>
          <Main data={variantData} />
        </DialogContent>
      </Dialog>
      {dataToDisplay()}
    </div>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(Main)
