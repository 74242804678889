import TextField from '@material-ui/core/TextField'
import React from 'react'

const regex = /^\d{14}$/
const input = {
  isValid: (element) => {
    return regex.test(element.value)
  },
  isEmpty: (element) => {
    return element.value.length === 0
  },
  errorMessage: (label) => {
    return `${label} is invalid`
  },
  render: (props, index) => {
    return (
      <TextField
        margin="dense"
        label={props.label + (props.required ? '*' : '')}
        type="text"
        fullWidth
        key={index}
        autoFocus={index === 0}
        defaultValue={props.value}
      />
    )
  },
  getValue: (element) => {
    return element.value
  },
}

export default input
