import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'

const RenderExcerpt = (props) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        className="dialog"
      >
        <DialogContent>
          <p
            style={{
              margin: '30px auto',
            }}
          >
            {props.data}
          </p>
        </DialogContent>
      </Dialog>
      <a
        style={{
          color: 'rgba(0, 0, 220)',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}
      >
        {props.paragraph}
      </a>
    </>
  )
}

export default RenderExcerpt
