/**
 * An abstract component that displays a collection in tabular form.
 * The component supports pagination, filtering, sorting and adding
 * new items.
 *
 * @author Fady Sameh
 * @edits Ahmed Samir Morsy
 */
import Pagination from '@material-ui/lab/Pagination'
import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQueryParams } from 'use-query-params'

import ActionButton from '../../actionButton/actionButton'
// import Paper from '@material-ui/core/Paper'
// import InputBase from '@material-ui/core/InputBase'
// import Divider from '@material-ui/core/Divider'
// import IconButton from '@material-ui/core/IconButton'
// import MenuIcon from '@material-ui/icons/Menu'
// import SearchIcon from '@material-ui/icons/Search'
// import DirectionsIcon from '@material-ui/icons/Directions'
import AdminGuard from '../../guards/adminGuard'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: '2px 4px',
//     display: 'flex',
//     alignItems: 'center',
//     width: 400,
//   },
//   input: {
//     marginLeft: theme.spacing(1),
//     flex: 1,
//   },
//   iconButton: {
//     padding: 10,
//   },
//   divider: {
//     height: 28,
//     margin: 4,
//   },
// }))
const AbstractCollection = (props) => {
  const isAdmin = useSelector((state) =>
    state.userReducer?.user?.roles?.includes('admin')
  )
  // initializing query params
  const [query, setQuery] = useQueryParams(props.queryParams)
  const [loading, setLoading] = useState(true)
  // const [search, setSearch] = useState(query.search || '')

  // this hook runs at the change of query params
  useEffect(() => {
    updateTable()
    // tableRef.current.className= "test"
  }, [query])

  const updateTable = (search) => {
    setLoading(true)
    if (search?.length > 0) {
      props
        .findAction({
          ...query,
          page: query.page || 1,
          limit: 10,
          search,
          sort: '-createdAt',
          ...props.actionAdditionalParam,
        })
        .then(() => setLoading(false))
    } else if (query.search?.length > 0) {
      props
        .findAction({
          ...query,
          page: query.page || 1,
          limit: 10,
          search: query.search,
          sort: '-createdAt',
          ...props.actionAdditionalParam,
        })
        .then(() => setLoading(false))
    } else {
      const q = { ...query }
      delete q.search
      props
        .findAction({
          ...q,
          page: query.page || 1,
          limit: 10,
          sort: '-createdAt',
          ...props.actionAdditionalParam,
        })
        .then(() => setLoading(false))
    }
  }

  const mainActions = props.mainActions || []

  return (
    <>
      <div className="btnActions">
        {mainActions.map((action) =>
          action.admin ? (
            <AdminGuard>
              <ActionButton
                buttonLabel={action.buttonLabel}
                buttonColor={action.buttonColor}
                withForm={action.withForm}
                form={action.form}
                onSubmit={(args) => {
                  action.onSubmit(args)
                  if (action.withReload) {
                    updateTable()
                  }
                }}
              />
            </AdminGuard>
          ) : (
            <ActionButton
              buttonLabel={action.buttonLabel}
              buttonColor={action.buttonColor}
              withForm={action.withForm}
              form={action.form}
              onSubmit={(args) => {
                action.onSubmit(args)
                if (action.withReload) {
                  updateTable()
                }
              }}
            />
          )
        )}
      </div>
      <div className={props.data?.length > 0 ? 'tableComponent' : 'tableEmpty'}>
        <MaterialTable
          title={props.title}
          isLoading={loading}
          data={props.data}
          columns={
            !isAdmin
              ? props.columns.filter((column) => !column.admin)
              : props.columns
          }
          options={{
            pageSize: 10,
            debounceInterval: 500,
            actionsColumnIndex: props.columns.length,
            searchText: query.search ? query.search : '',
            search: true,
            tableLayout: 'auto',
            // rowStyle:{
            //   display: 'block'
            // }
          }}
          // tableRef={tableRef}
          // customFilterAndSearch={test}
          onSearchChange={(search) => {
            setQuery({ search, page: 1 })
            updateTable(search)
          }}
          actions={
            !isAdmin
              ? props.actions.filter((action) => !action.admin)
              : props.actions
          }
          components={{
            Pagination: () => (
              <>
                <Pagination
                  page={query.page || 1}
                  count={props.pages}
                  color="primary"
                  showFirstButton
                  showLastButton
                  onChange={(event, value) => {
                    // console.log("on paginate", query);
                    setQuery({ ...query, page: value })
                  }}
                  className="customPagination"
                />
              </>
            ),
            // Toolbar: () => (
            //   <div className="tootbar">
            //     <h6>{props.title}</h6>
            //     <Paper component="form" className={classes.root}>
            //       <InputBase
            //         className={classes.input}
            //         placeholder="Search..."
            //         value={search}
            //         onChange={event => {
            //           setSearch(event.target.value)
            //           // setQuery({ search, page: 1 })
            //           // setTimeout(() =>{
            //           //   updateTable(search)
            //           // },[1000])
            //         }}
            //       />
            //     </Paper>
            //   </div>
            // )
          }}
        />
      </div>
    </>
  )
}

export default AbstractCollection
