import './styles/home.css'

import React from 'react'
import { withRouter } from 'react-router-dom'

const Home = (props) => {
  return (
    <div className="home">
      <p className="header">Home</p>

      <div className="part">
        <p className="title">Latest Update</p>
        <div className="contents">
          <div
            onClick={() => props.history.push('/employees')}
            className="contentHolder"
          >
            <p>Employees</p>
            <ul
              style={{
                textAlign: 'left',
              }}
            >
              <li>4 new employees</li>
              <li>2 banned employees</li>
              <li>7 updated employees</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="part">
        <p className="title">Pages</p>
        <div className="contents">
          <div
            onClick={() => props.history.push('/employees')}
            className="contentHolder"
          >
            <p>Employees</p>
            <div className="imgHolder">
              <img src="assets/images/employee.jpg" alt="employee's pic" />
            </div>
          </div>

          <div
            onClick={() => props.history.push('/events')}
            className="contentHolder"
          >
            <p>Events</p>
            <div className="imgHolder">
              <img src="assets/images/event.jpg" alt="event's pic" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Home)
