import React from 'react'

import getNestedObject from '../getNestedObject'

const SendingMethods =
  (emailedPath, smsedPath, whatsAppedPath) => (rowData) => {
    const emailed = getNestedObject(rowData, emailedPath) ? 'Yes' : 'No'
    const smsed = getNestedObject(rowData, smsedPath) ? 'Yes' : 'No'
    const whatsApped = getNestedObject(rowData, whatsAppedPath) ? 'Yes' : 'No'

    return <p>{`Emailed:${emailed} SMSed:${smsed} Whatsapped:${whatsApped}`}</p>
  }

export default SendingMethods
