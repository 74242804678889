import React from 'react'
import { connect } from 'react-redux'

import { login } from '../store/actions/user'
import AbstractDialog from './dialog/abstractDialog'

const Login = (props) => {
  return (
    <AbstractDialog
      open
      title="Login"
      info="You must login before viewing this page."
      inputs={[
        {
          name: 'email',
          label: 'Email',
          type: 'email',
          required: true,
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true,
        },
      ]}
      onSubmit={(body) => props.login(body)}
      forget
    />
  )
}

const mapDispatchToProps = {
  login,
}

export default connect(null, mapDispatchToProps)(Login)
