import React from 'react'

import getNestedObject from '../getNestedObject'

const BooleanString = (path) => (rowData) => {
  const boolean = getNestedObject(rowData, path)
  return <p>{boolean ? 'Yes' : 'No'}</p>
}

export default BooleanString
