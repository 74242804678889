import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { NumberParam, StringParam } from 'use-query-params'

import {
  addEvent,
  findArchiveEvents,
  findEvents,
  importEvent,
} from '../../store/actions'
import DateTimeString from '../util/customRender/dateTimeString'
import Excerpt from '../util/customRender/excerpt'
import SelectRender from '../util/customRender/selectRender'
import AbstractCollection from './abstractCollection/abstractCollection'

const Events = (props) => {
  const history = useHistory()
  const [isArchive, setIsArchive] = React.useState(false)
  React.useEffect(() => {
    // console.log(props.events);
  }, [])
  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: isArchive ? 'Show Events' : 'Show Archive Events',
          buttonColor: isArchive ? 'success' : 'primary',
          admin: !isArchive,
          withReload: true,
          withForm: false,
          onSubmit: () => {
            setIsArchive(!isArchive)
            if (isArchive) {
              props.findEvents({
                page: 1,
                limit: 10,
                sort: '-createdAt',
              })
            } else {
              props.findArchiveEvents({
                page: 1,
                limit: 10,
                sort: '-createdAt',
              })
            }
          },
          form: {},
        },
        {
          buttonLabel: 'Create Event',
          admin: true,
          buttonColor: 'primary',
          withReload: true,
          onSubmit: (args) => {
            // console.log(args);
            props.addEvent(args)
          },
          withForm: true,
          // name, company name, date, venue and description.
          form: {
            title: 'Create Event',
            info: 'A form to create an event to be added to the system',
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'company',
                label: 'Company Name',
                type: 'text',
                required: true,
              },
              {
                name: 'date',
                label: 'Date',
                type: 'date',
                required: true,
              },
              {
                name: 'venue',
                label: 'Venue',
                type: 'text',
                required: true,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'textArea',
                required: true,
              },
            ],
          },
        },
        // {
        //   buttonLabel: 'Import Event',
        //   admin:true,
        //   buttonColor: 'primary',
        //   withReload: true,
        //   onSubmit: args => {
        //     // console.log(args);
        //     props.importEvent({
        //       _id: args.event
        //     })
        //   },
        //   withForm: true,
        //   // name, company name, date, venue and description.
        //   form: {
        //     title: 'Import Event',
        //     info: '',
        //     inputs: [
        //       {
        //         name: 'event',
        //         label: 'Event ID',
        //         type: 'text',
        //         required: true
        //       }
        //     ]
        //   }
        // }
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam,
      }}
      title={isArchive ? 'Archive Events' : 'Events'}
      data={isArchive ? props.archiveEvents : props.events}
      columns={[
        {
          title: 'Name',
          field: 'name',
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Company',
          field: 'company',
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Date',
          field: 'date',
          render: DateTimeString(['date']),
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Description',
          field: 'description',
          render: Excerpt(['description']),
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Venue',
          field: 'venue',
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          admin: true,
          title: 'Archive',
          field: 'deleted',
          render: isArchive
            ? SelectRender(['deleted', 'eventRestore'])
            : SelectRender(['deleted', 'eventArchive']),
          customFilterAndSearch: () => {
            return true
          },
        },
      ]}
      actions={
        isArchive
          ? []
          : [
              {
                onClick: (event, row) => history.push(`/event/${row._id}`),
                icon: OpenInNewIcon,
              },
            ]
      }
      findAction={isArchive ? props.findArchiveEvents : props.findEvents}
      pages={isArchive ? props.archivePages : props.pages}
    />
  )
}

const mapStateToProps = (store) => {
  return {
    events: store.eventReducer.events,
    pages: store.eventReducer.pages,
    archiveEvents: store.eventReducer.archiveEvents,
    archivePages: store.eventReducer.archivePages,
  }
}

const mapDispatchToProps = {
  findEvents,
  findArchiveEvents,
  addEvent,
  importEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(Events)
