/**
 * Display content only if user has admin role
 */
import React from 'react'
import { connect } from 'react-redux'

import Guard from './guard'

const AdminGuard = (props) => {
  return (
    <Guard condition={props.user?.roles?.includes('admin')}>
      {props.children}
    </Guard>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  }
}

export default connect(mapStateToProps)(AdminGuard)
