import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { PieChart } from '@opd/g2plot-react'
import React, { useEffect } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import SwipeableViews from 'react-swipeable-views'
import { JsonParam, useQueryParams, withDefault } from 'use-query-params'

import { eventStatus, findEvents } from '../../store/actions'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}))

const Statistics = () => {
  const dispatch = useDispatch()
  const [query, setQuery] = useQueryParams({
    selectedEvents: withDefault(JsonParam, []),
  })
  // AutoComplete Search Events
  const [open, setOpen] = React.useState(false)
  const [searchInput, setSearchInput] = React.useState('')
  const [events, setEvents] = React.useState([])
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  // const [selectedEvents, setSelectedEvents] = React.useState([]);
  const loading = open

  React.useEffect(() => {
    if (!loading) {
      return undefined
    }

    dispatch(
      findEvents({
        page: 1,
        limit: 10,
        sort: '-createdAt',
        search: searchInput,
      })
    ).then((res) => {
      const listOptions = res.data.entities
      setEvents(listOptions)
    })
  }, [loading, searchInput])

  React.useEffect(() => {
    if (!open) {
      setEvents([])
    }
    setSearchInput('')
  }, [open])

  // Charts
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const [configPie] = React.useState({
    forceFit: true,
    radius: 0.8,
    data: [],
    angleField: 'value',
    colorField: 'type',
    label: {
      visible: true,
      type: 'outer',
    },
    legend: {
      visible: true,
      position: 'left-bottom',
    },
  })

  const [dataInvitationViewership, setDataInvitationViewership] =
    React.useState({})

  const [dataInvitationResponse, setDataInvitationResponse] = React.useState({})

  const [dataConfirmationMethod, setDataConfirmationMethod] = React.useState({})

  const [dataAttendancePercentage, setDataAttendancePercentage] =
    React.useState({})

  const [dataActualAttendance, setDataActualAttendance] = React.useState({})

  useEffect(() => {
    if (query.selectedEvents.length > 0) {
      const dataInvitiationViewershipObj = {}
      const dataActualAttendenceObj = {}
      const dataPercentageObj = {}
      const dataConfirmationObj = {}
      const dataInvitationResponseObj = {}

      const promises = query.selectedEvents.map((event) => {
        const eventID = event.id
        return dispatch(
          eventStatus({
            event: eventID,
            page: 1,
            limit: 10,
            sort: '-createdAt',
          })
        )
      })

      Promise.all(promises).then((responses) => {
        responses.forEach((res, i) => {
          const eventID = query.selectedEvents[i].id
          Object.assign(dataInvitiationViewershipObj, {
            [eventID]: [
              {
                type: 'Viewed invitations',
                value: res.data['Invitation viewership:']['Viewed Invitations'],
              },
              {
                type: 'Not Viewed Invitations',
                value:
                  res.data['Invitation viewership:']['Not Viewed Invitations'],
              },
            ],
          })

          Object.assign(dataPercentageObj, {
            [eventID]: [
              {
                type: 'Confirmed attendance',
                value:
                  res.data['Attendance Percentage:']['Confirmed Attendance'],
              },
              {
                type: 'unattended',
                value: res.data['Attendance Percentage:'].Unattended,
              },
            ],
          })

          Object.assign(dataConfirmationObj, {
            [eventID]: [
              {
                type: 'Confirmed using QR code',
                value:
                  res.data['Confirmation Method:']['Confirmed using QR code'],
              },
              {
                type: 'confirmed through system',
                value:
                  res.data['Confirmation Method:']['Confirmed through System'],
              },
              {
                type: 'confirmed manually',
                value: res.data['Confirmation Method:']['Confirmed Manually'],
              },
            ],
          })
          Object.assign(dataInvitationResponseObj, {
            [eventID]: [
              {
                type: 'Confirmed invitations',
                value: res.data['Invitation Response:']['Accepted Invitations'],
              },
              {
                type: 'Declined invitations',
                value: res.data['Invitation Response:']['Rejected Invitations'],
              },
              {
                type: 'Pending invitations',
                value: res.data['Invitation Response:']['Pending Invitations'],
              },
            ],
          })

          Object.assign(dataActualAttendenceObj, {
            [eventID]: [
              {
                type: ' Confirmed invitation',
                value: res.data['Actual Attendance:']['Confirmed Invitation'],
              },
              {
                type: 'Confirmed attendance',
                value: res.data['Actual Attendance:']['Confirmed Attendance'],
              },
            ],
          })
        })

        // update state
        setDataInvitationViewership(dataInvitiationViewershipObj)
        setDataAttendancePercentage(dataPercentageObj)
        setDataConfirmationMethod(dataConfirmationObj)
        setDataInvitationResponse(dataInvitationResponseObj)
        setDataActualAttendance(dataActualAttendenceObj)
      })
    }
  }, [query])

  const searchWidth = () => {
    if (isTabletOrMobile) {
      return { style: { width: '100%' } }
    }
    return { style: { width: '40%' } }
  }
  const canvasWidth = () => {
    if (isTabletOrMobile) {
      return {}
    }
    return { style: { width: '30%' } }
  }
  const canvasDivWidth = () => {
    if (isTabletOrMobile) {
      return { style: { width: '100%' } }
    }
    return { style: { width: '100%', display: 'flex' } }
  }
  return (
    <div>
      <Autocomplete
        multiple
        id="asynchronous-demo"
        {...searchWidth()}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        onChange={(event, value) => {
          setQuery({
            selectedEvents: value,
          })
        }}
        defaultValue={query.selectedEvents}
        getOptionSelected={(option, value) => option.name === value.value}
        getOptionLabel={(option) => option.name}
        options={
          events
            ? events.map((event) => {
                return {
                  id: event._id,
                  name: event.name,
                }
              })
            : []
        }
        loading={loading}
        renderInput={(params) => (
          <DebounceInput
            element={TextField}
            debounceTimeout={500}
            {...params}
            onChange={(event) => {
              setSearchInput(event.target.value)
            }}
            label="Compare Events"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
      <p
        style={{
          marginTop: '100px',
          fontSize: '30px',
          fontWeight: 'bold',
        }}
      >
        Statistics
      </p>
      <div
        className={classes.root}
        style={{
          width: '100%',
          marginTop: '20px',
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Invitation viewership" {...a11yProps(0)} />
            <Tab label="Invitation response" {...a11yProps(1)} />
            <Tab label="Confirmation method" {...a11yProps(2)} />
            <Tab label="Attendance percentage" {...a11yProps(3)} />
            <Tab label="Actual attendance" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <div className={classes.root} {...canvasDivWidth()}>
          {query.selectedEvents.map((event) => (
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
              {...canvasWidth()}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <PieChart
                  {...configPie}
                  data={dataInvitationViewership[event.id]}
                  title={{
                    visible: true,
                    text: event.name,
                    alignTo: 'middle',
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <PieChart
                  {...configPie}
                  data={dataInvitationResponse[event.id]}
                  title={{
                    visible: true,
                    text: event.name,
                    alignTo: 'middle',
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <PieChart
                  {...configPie}
                  data={dataConfirmationMethod[event.id]}
                  title={{
                    visible: true,
                    text: event.name,
                    alignTo: 'middle',
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <PieChart
                  {...configPie}
                  data={dataAttendancePercentage[event.id]}
                  title={{
                    visible: true,
                    text: event.name,
                    alignTo: 'middle',
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                <PieChart
                  {...configPie}
                  data={dataActualAttendance[event.id]}
                  title={{
                    visible: true,
                    text: event.name,
                    alignTo: 'middle',
                  }}
                />
              </TabPanel>
            </SwipeableViews>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Statistics
