/**
 * This file is used to show array as tables
 *
 * @author Ahmed Samir Morsy
 */

import MaterialTable from 'material-table'
import React from 'react'
import { useQueryParams } from 'use-query-params'

const ItemPaginate = (props) => {
  const [query, setQuery] = useQueryParams(props.queryParams)

  return (
    // <div className="tableSingleComponent">
    <MaterialTable
      title={props.title}
      data={props.data}
      columns={props.columns}
      options={{
        pageSize: props.data.length > 10 ? 10 : props.data.length,
        pageSizeOptions: [5, 10, 20, 30],
        debounceInterval: 500,
        searchText: query.search,
        tableLayout: 'auto',
        initialPage: query.page,
        actionsColumnIndex: props.columns.length,
      }}
      style={{
        overflowX: 'hidden',
        width: '100%',
      }}
      onChangePage={(page) => {
        setQuery({ search: query.search, page })
      }}
      onSearchChange={(search) => {
        setQuery({ search, page: 1 })
      }}
      actions={props.actions}
    />
    // </div>
  )
}

export default ItemPaginate
