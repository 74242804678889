import TextField from '@material-ui/core/TextField'
import React from 'react'

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.){8,}$/

const input = {
  isValid: (element) => {
    return passwordRegex.test(element.value)
  },
  isEmpty: (element) => {
    return element.value.length === 0
  },
  errorMessage: () => {
    return 'Password should contain at least one digit, one lower case letter, one upper case letter, and at least 8 characters !'
  },
  render: (props, index) => {
    return (
      <TextField
        margin="dense"
        label={props.label + (props.required ? '*' : '')}
        type="password"
        fullWidth
        key={index}
        autoFocus={index === 0}
      />
    )
  },
  getValue: (element) => {
    return element.value
  },
}

export default input
