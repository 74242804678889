import React from 'react'

import getNestedObject from '../getNestedObject'
import RenderExcerpt from './renderExcert'

const Excerpt = (path) => (rowData) => {
  let paragraph = getNestedObject(rowData, path)
  if (paragraph.length > 100) paragraph = `${paragraph.substring(0, 100)}...`
  return (
    <RenderExcerpt
      paragraph={paragraph}
      data={getNestedObject(rowData, path)}
    />
  )
}

export default Excerpt
