import {
  ADD_EVENT,
  EVENT_STATUS,
  FIND_ARCHIVE_EVENT,
  FIND_ARCHIVE_EVENTS,
  FIND_EVENT,
  FIND_EVENTS,
  RESTORE_EVENT,
  ARCHIVE_EVENT,
  UPDATE_EVENT,
  UPLOAD_EVENT_IMAGE,
  UPLOAD_EVENT_HEADER_IMAGE,
  UPLOAD_EVENT_FOOTER_IMAGE
} from '../types'
import { action, noDisaptchAction } from './factory'

const modelName = 'event'
const routeName = 'event'

export const findEvents = action(
  FIND_EVENTS,
  `/${routeName}/readAll`,
  `${modelName}s`
)

export const findArchiveEvents = action(
  FIND_ARCHIVE_EVENTS,
  `/${routeName}/readAllDeleted`,
  `${modelName}s`
)

export const findEvent = action(
  FIND_EVENT,
  `/${routeName}/readOne`,
  `${modelName}`
)

export const findArchiveEvent = action(
  FIND_ARCHIVE_EVENT,
  `/${routeName}/readDeleted`,
  `${modelName}`
)

export const addEvent = action(
  ADD_EVENT,
  `/${routeName}/create`,
  `${modelName}`
)

export const updateEvent = action(
  UPDATE_EVENT,
  `/${routeName}/update`,
  `${modelName}`
)

export const restoreEvent = action(
  RESTORE_EVENT,
  `/${routeName}/restoreByUpdate`,
  `${modelName}`
)

export const archiveEvent = action(
  ARCHIVE_EVENT,
  `/${routeName}/deleteByUpdate`,
  `${modelName}`
)

export const eventStatus = action(
  EVENT_STATUS,
  `/${routeName}/guestStats`,
  `${modelName}`
)

export const uploadEventImage = action(
  UPLOAD_EVENT_IMAGE,
  `/${routeName}/updateImage/image`,
  `${modelName}s`
)

export const uploadEventHeaderImage = action(
  UPLOAD_EVENT_HEADER_IMAGE,
  `/${routeName}/updateImage/headerImage`,
  `${modelName}s`
)

export const uploadEventFooterImage = action(
  UPLOAD_EVENT_FOOTER_IMAGE,
  `/${routeName}/updateImage/footerImage`,
  `${modelName}s`
)

export const importEvent = noDisaptchAction(
  '/offline/importData',
  true,
  () => 'Event Imported',
  () => 'Error'
)

export const exportEvent = noDisaptchAction(
  '/offline/exportData',
  true,
  () => 'Event Exported',
  () => 'Error'
)
