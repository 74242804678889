import React from 'react'
import { connect } from 'react-redux'

import { resetPassword } from '../store/actions'
import AbstractDialog from './dialog/abstractDialog'

const ResetPassword = (props) => {
  return (
    <AbstractDialog
      open
      title="Reset Password"
      info="Now you can reset your password!"
      inputs={[
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          required: true,
        },
        {
          name: 'confirmPassword',
          label: 'Confirm Password',
          type: 'password',
          required: true,
        },
      ]}
      onSubmit={(body) => {
        delete body.confirmPassword
        props.resetPassword({
          ...body,
          urlToken: props.match.params.token,
        })
      }}
    />
  )
}

const mapDispatchToProps = {
  resetPassword,
}

export default connect(null, mapDispatchToProps)(ResetPassword)
