import {
  ADD_GUEST,
  DELETE_GUEST,
  EXPORT_GUEST_LIST,
  EXPORT_GUEST_LIST_FILE,
  FIND_GUEST,
  FIND_GUESTS,
  UPDATE_GUEST,
  UPDATE_GUEST_ALLOWED_ENTRIES,
} from '../types'
import { action, actionGetToken, noDisaptchAction } from './factory'

const modelName = 'guest'
const routeName = 'guest'

export const findGuests = action(
  FIND_GUESTS,
  `/${routeName}/readGuestList`,
  `${modelName}s`
)

export const findGuest = action(
  FIND_GUEST,
  `/${routeName}/readOne`,
  `${modelName}`
)

export const addGuest = action(
  ADD_GUEST,
  `/${routeName}/create`,
  `${modelName}`
)

export const updateGuest = action(
  UPDATE_GUEST,
  `/${routeName}/update`,
  `${modelName}`
)

export const updateGuestAllowedEntries = action(
  UPDATE_GUEST_ALLOWED_ENTRIES,
  `/${routeName}/updateAllowedEntries`,
  `${modelName}`
)

export const deleteGuest = action(
  DELETE_GUEST,
  `/${routeName}/deleteByUpdate`,
  `${modelName}`
)

export const exportGuestList = action(
  EXPORT_GUEST_LIST,
  `/${routeName}/guestListToPDF`,
  `${modelName}`
)

export const exportGuestListFile = actionGetToken(
  EXPORT_GUEST_LIST_FILE,
  `/${routeName}/guestListPDF`,
  `${modelName}`
)

export const sendEmails = noDisaptchAction(
  `/${routeName}/sendEmailInvitations`,
  true,
  () => 'Emails are being sent out',
  () => ''
)

export const sendWhatsapp = noDisaptchAction(
  `/${routeName}/sendWhatsappInvitations`,
  true,
  () => 'WhatsApp messages are being sent out',
  () => ''
)

export const sendSMS = noDisaptchAction(
  `/${routeName}/sendSMSInvitations`,
  true,
  () => 'SMSs are being sent out',
  () => ''
)

export const craeteInvitations = noDisaptchAction(
  `/${routeName}/guestListToPDFs`,
  true,
  () => 'PDFs are getting generated',
  () => 'Process is already running'
)

export const deleteAllGuests = noDisaptchAction(
  `/${routeName}/deleteAllGuests`,
  true,
  () => 'All guests are being deleted',
  () => 'Process failed'
)
