/**
 * A protected route that prompts the user to login if they are unauthenticated
 *
 * @author Fady Sameh
 */
import React from 'react'
import { Route } from 'react-router-dom'

import Login from '../login'
import AuthGuard from './authGuard'
import UnauthGuard from './unauthGuard'

const AuthRoute = (props) => {
  return (
    <Route path={props.path}>
      <AuthGuard>{props.component}</AuthGuard>
      <UnauthGuard>
        <Login />
      </UnauthGuard>
    </Route>
  )
}

export default AuthRoute
