import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { NumberParam, StringParam } from 'use-query-params'

import { addEmployee, findEmployees } from '../../store/actions/employee'
import BooleanString from '../util/customRender/booleanString'
import FullName from '../util/customRender/fullName'
import SelectRender from '../util/customRender/selectRender'
import AbstractCollection from './abstractCollection/abstractCollection'

const Employees = (props) => {
  const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: 'Invite Employee',
          buttonColor: 'primary',
          withReload: true,
          onSubmit: (args) => {
            args.roles = [args.role]
            delete args.role
            props.addEmployee(args)
          },
          withForm: true,
          form: {
            title: 'Invite Employee',
            info: 'An ivitation will be sent to this employee to collaborate in the system',
            inputs: [
              {
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
              },
              {
                name: 'role',
                label: 'Role',
                type: 'select',
                options: [
                  {
                    label: 'Admin',
                    value: 'admin',
                  },
                  {
                    label: 'Employee',
                    value: 'employee',
                  },
                ],
              },
            ],
          },
        },
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam,
      }}
      title="Employees"
      data={props.employees}
      columns={[
        {
          title: 'Name',
          field: 'name',
          render: FullName(['name', 'firstName'], ['name', 'lastName']),
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Email',
          field: 'email',
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Ban',
          field: 'deleted',
          render: SelectRender(['deleted', 'ban']),
          customFilterAndSearch: () => {
            return true
          },
        },
        {
          title: 'Confirmed',
          field: 'emailConfirmed',
          render: BooleanString(['emailConfirmed']),
          customFilterAndSearch: () => {
            return true
          },
        },
      ]}
      actions={[
        {
          onClick: (event, row) => history.push(`/employee/${row._id}`),
          icon: OpenInNewIcon,
        },
      ]}
      findAction={props.findEmployees}
      pages={props.pages}
    />
  )
}

const mapStateToProps = (store) => {
  return {
    employees: store.employeeReducer.employees,
    pages: store.employeeReducer.pages,
  }
}

const mapDispatchToProps = {
  findEmployees,
  addEmployee,
}

export default connect(mapStateToProps, mapDispatchToProps)(Employees)
