import {
  FORGET_PASSWORD,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  UPDATE_USER,
} from '../types'
import { action, actionToken, loginAction, registerAction } from './factory'

const routeName = 'user'
const modelName = 'user'

export const logOut = () => async (dispatch) => {
  dispatch({ type: LOGOUT_USER })
}

export const login = loginAction(LOGIN_USER, '/user/login', modelName)

export const register = registerAction()

export const updateUser = action(
  UPDATE_USER,
  `/${routeName}/update`,
  `${modelName}`
)

export const forgetPassword = action(
  FORGET_PASSWORD,
  `/${routeName}/forgotPassword`,
  `${modelName}`
)

export const resetPassword = actionToken(
  RESET_PASSWORD,
  `/${routeName}/resetPassword`,
  `${modelName}`
)
