import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { forgetPassword } from '../../store/actions'
import Guard from '../guards/guard'
import inputFactory from '../inputs/inputFactory'

const AbstractDialog = (props) => {
  const [formValid, setFormValid] = useState(props?.submitNoChange || false)
  const [errorMessage, setErrorMessage] = useState('')

  const inputs = props.inputs || []
  const formElements = inputs.map((inputProps, index) => {
    // console.log(inputProps)
    const input = inputFactory(inputProps.type)
    return input.render(inputProps, index)
  })

  const checkFormValidity = (event) => {
    const form = event.currentTarget
    let tempFormValid = true

    if (inputs.length > 0) {
      for (let i = inputs.length - 1; i >= 0; i--) {
        const element = form.elements[i]
        const description = props.inputs[i]
        const input = inputFactory(description.type)
        if (description.required && input.isEmpty(element)) {
          setErrorMessage(`${description.label} is required`)
          setFormValid(false)
          tempFormValid = false
        } else if (!input.isEmpty(element) && !input.isValid(element)) {
          setErrorMessage(input.errorMessage(description.label))
          setFormValid(false)
          tempFormValid = false
        }
      }
    } else {
      const inpss = event.currentTarget.elements
      if (inpss)
        for (let i = inpss.length - 1; i >= 0; i--) {
          if (inpss[i].nodeName === 'INPUT') {
            // console.log(inpss[i].name)
            if (
              inpss[i].value.length === 0 &&
              inpss[i].name.indexOf('specification') === -1
            ) {
              setErrorMessage(`${inpss[i].name} is required`)
              setFormValid(false)
              tempFormValid = false
            }
          }
        }
    }
    if (tempFormValid) {
      if (props.customValidation) {
        let err = props.customValidation(inputs,form.elements)
        if (err !== null) {
          setFormValid(false)
          setErrorMessage(err);
        }
        else
          setFormValid(true)
      }
      else {
        setFormValid(true)
      }
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    const body = {}
    if (inputs.length > 0)
      for (let i = 0; i < inputs.length; i++) {
        const element = form.elements[i]
        const description = props.inputs[i]
        const input = inputFactory(description.type)
        if (!input.isEmpty(element)) {
          body[description.name] = input.getValue(element)
        }
      }
    else {
      const inps = event.target.elements
      for (let i = 0; i < inps.length; i++) {
        if (inps[i].nodeName === 'INPUT' && inps[i].value.length > 0) {
          if (inps[i].name.indexOf('specifications-') === -1) {
            body[inps[i].name] = inps[i].value
          } else {
            if (!body.specifications) body.specifications = {}
            body.specifications[inps[i].name.split('-')[1]] = inps[i].value
          }
        }
      }
    }
    props.onSubmit(body)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      // scroll="paper"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <form
          action="/"
          method="POST"
          id="mainForm"
          onSubmit={onSubmit}
          onChange={checkFormValidity}
          onLoad={checkFormValidity}
        >
          <DialogContent>
            <DialogContentText style={{whiteSpace: "pre-line"}}>{props.info}</DialogContentText>
            <Guard condition={!formValid}>
              <p style={{ color: '#ff0000' }}>{errorMessage}</p>
            </Guard>
            {inputs.length > 0 ? formElements : props.data}
          </DialogContent>
          {props.noSubmitBtn ? (
            <></>
          ) : (
            <>
              {props.forget ? (
                <a
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  className="hyperLink"
                  onClick={() => {
                    const form = document.getElementById('mainForm').elements
                    const mail = form[0].value
                    if (mail.length > 0) {
                      props.forgetPassword({
                        email: mail,
                      })
                    } else {
                      setErrorMessage(
                        'Enter correct mail to send link for reseting the password.'
                      )
                    }
                    // console.log(mail)
                  }}
                >
                  Forget Password?
                </a>
              ) : (
                <></>
              )}
              <DialogActions>
                <Button disabled={!formValid} type="submit" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </form>
      </DialogContent>
    </Dialog>
  )
}

const mapDispatchToProps = {
  forgetPassword,
}

export default connect(null, mapDispatchToProps)(AbstractDialog)
