import {
  FIND_ARCHIVE_EVENT,
  FIND_ARCHIVE_EVENTS,
  FIND_EVENT,
  FIND_EVENTS,
  UPDATE_EVENT,
  ARCHIVE_EVENT
} from '../types'

const initialState = {
  event: [],
  archiveEvent: [],
  events: [],
  archiveEvents: [],
  pages: 0,
  archivePages: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_EVENTS:
      return {
        ...state,
        events: action.data.events.entities,
        pages: action.data.events.pages,
      }
    case FIND_ARCHIVE_EVENTS:
      return {
        ...state,
        archiveEvents: action.data.events.entities,
        archivePages: action.data.events.pages,
      }
    case FIND_EVENT:
      return {
        ...state,
        event: action.data.event,
      }
    case FIND_ARCHIVE_EVENT:
      return {
        ...state,
        archiveEvent: action.data.event,
      }
    case UPDATE_EVENT:
      return {
        ...state,
        event: action.data.event,
      }
    case ARCHIVE_EVENT:
      return {
        ...state,
        event: action.data.event,
      }

    default:
      return state
  }
}
