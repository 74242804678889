/**
 * This file is implemented to show orders single component
 *
 * @author Ahmed Samir Morsy
 */

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import GetApp from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { PieChart } from '@opd/g2plot-react'
import swal from '@sweetalert/with-react'
import axios from 'axios'
import React, { useRef } from 'react'
import QrReader from 'react-qr-reader'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { useReactToPrint } from 'react-to-print'
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'

import {
  addGuest,
  craeteInvitations,
  deleteAllGuests,
  deleteGuest,
  eventStatus,
  exportEvent,
  exportGuestList,
  exportGuestListFile,
  findEvent,
  findGuest,
  findGuests,
  sendEmails,
  sendSMS,
  sendWhatsapp,
  updateEvent,
  updateGuest,
  updateGuestAllowedEntries,
  uploadEventImage,
  uploadEventHeaderImage,
  uploadEventFooterImage
} from '../../store/actions'
import ActionButton from '../actionButton/actionButton'
import AbstractCollection from '../collectionComponents/abstractCollection/abstractCollection'
import AbstractDialog from '../dialog/abstractDialog'
import AdminGuard from '../guards/adminGuard'
// import ExcelReader from '../dataUpload/excelReader'
import UploadExcel from '../uploadExcel'
import BooleanString from '../util/customRender/booleanString'
import Entries from '../util/customRender/entries'
import FullName from '../util/customRender/fullName'
import SendingMethods from '../util/customRender/sentMethods'
import Main from './main'
import TextField from '@material-ui/core/TextField'
import moment from 'moment-timezone'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}))

class NameTagePrint extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // minHeight: window.innerHeight,
          height: '105px',
          textAlign: 'center',
          flexDirection: 'column',
          padding: '15px',
          boxSizing: 'border-box',
        }}
      >
        <p style={{ fontSize: '32px', margin: 0, marginBottom: '2px' }}>
          {this.props.invitationNameTag}
        </p>
        <p style={{ fontSize: '22px', margin: 0 }}>
          {this.props.invitationCompoanyName}
        </p>
        <p style={{ fontSize: '15px', margin: 0 }}>
          {this.props.invitationJobTitle}
        </p>
        <p style={{ fontSize: '15px', margin: 0 }}>
          {this.props.invitationSeatingNumber}
        </p>
      </div>
    )
  }
}

const Event = (props) => {
  const printComponentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  })
  const history = useHistory()
  const params = useParams()
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    opened: BooleanParam,
    sent: BooleanParam,
    decision: StringParam,
  })

  const [invitationNameTag, setInvitationNameTag] = React.useState('')
  const [invitationCompoanyName, setInvitationCompoanyName] = React.useState('')
  const [invitationJobTitle, setInvitationJobTitle] = React.useState('')
  const [invitationSeatingNumber, setInvitationSeatingNumber] = React.useState('')

  const [printJobTitle, setPrintJobTitle] = React.useState(true)
  const [printCompanyName, setPrintCompanyName] = React.useState(true)
  const [printNames, setPrintNames] = React.useState(true)
  const [printSeatingNumber, setPrintSeatingNumber] = React.useState(true)
  const [refresh, setRefresh] = React.useState(true);

  React.useEffect(() => {
    if (refresh === false) return;
    // let search = decodeURI(window.location.search)
    // while(search.indexOf("%3D") !== -1)
    //   search = search.replace("%3D","=")
    // while(search.indexOf("%3F") !== -1)
    //   search = search.replace("%3F","?")
    // // console.log(search);
    // const data = search.split("?")
    // const body = {
    //   sent: "",
    //   opened: "",
    //   decision: ""
    // }
    // data.map(
    //   e => {
    //     if(e.indexOf("=") !== -1)
    //       switch (e.split("=")[0]) {
    //         case "sent":
    //           body.sent = e.split("=")[1] === "true" ? true : false;
    //           setInvitationSent(body.sent)
    //           break;
    //         case "opened":
    //           body.opened = e.split("=")[1] === "true" ? true : false;
    //           setInvitationOpened(body.opened)
    //           break;
    //         case "decision":
    //           body.decision = e.split("=")[1];
    //           setInvitationStatus(body.decision)
    //           break;

    //         default:
    //           break;
    //       }
    //   }
    // )
    // if(body.sent === "") {
    //   delete body.sent
    // }
    // if(body.opened === "") {
    //   delete body.opened
    // }
    // if(body.decision === "") {
    //   delete body.decision
    // }

    // console.log("onload");
    // console.log(query);
    setInvitationSent(query.sent === undefined ? '' : !!query.sent)
    setInvitationOpened(query.opened === undefined ? '' : !!query.opened)
    setInvitationStatus(query.decision || '')
    setRefresh(false);

    props.findEvent({ _id: params.id }).then((res) => {
      // console.log(res);
      const q = { ...query }
      if (q?.search?.trim()?.length === 0) delete q.search
      if (res.data?._id) {
        props.findGuests({
          event: res.data._id,
          page: 1,
          limit: 10,
          sort: '-createdAt',
          ...q,
        })
        props
          .eventStatus({
            event: res.data._id,
            page: 1,
            limit: 10,
            sort: '-createdAt',
          })
          .then((res) => {
            setDataActualAttendance([
              {
                type: ' Confirmed invitation',
                value: res.data['Actual Attendance:']['Confirmed Invitation'],
              },
              {
                type: 'Confirmed attendance',
                value: res.data['Actual Attendance:']['Confirmed Attendance'],
              },
            ])

            setDataAttendancePercentage([
              {
                type: 'Confirmed attendance',
                value:
                  res.data['Attendance Percentage:']['Confirmed Attendance'],
              },
              {
                type: 'unattended',
                value: res.data['Attendance Percentage:'].Unattended,
              },
            ])

            setDataConfirmationMethod([
              {
                type: 'Confirmed using QR code',
                value:
                  res.data['Confirmation Method:']['Confirmed using QR code'],
              },
              {
                type: 'confirmed through system',
                value:
                  res.data['Confirmation Method:']['Confirmed through System'],
              },
              {
                type: 'confirmed manually',
                value: res.data['Confirmation Method:']['Confirmed Manually'],
              },
            ])

            setDataInvitationResponse([
              {
                type: 'Confirmed invitations',
                value: res.data['Invitation Response:']['Accepted Invitations'],
              },
              {
                type: 'Declined invitations',
                value: res.data['Invitation Response:']['Rejected Invitations'],
              },
              {
                type: 'Pending invitations',
                value: res.data['Invitation Response:']['Pending Invitations'],
              },
            ])

            setDataInvitationViewership([
              {
                type: 'Viewed invitations',
                value: res.data['Invitation viewership:']['Viewed Invitations'],
              },
              {
                type: 'Not Viewed Invitations',
                value:
                  res.data['Invitation viewership:']['Not Viewed Invitations'],
              },
            ])
          })
      }
    })
  }, [refresh])

  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const [open, setOpen] = React.useState(false)
  const [inputs, setInputs] = React.useState([])
  const [title, setTitle] = React.useState('')
  const [submitType, setSubmitType] = React.useState('')
  const [data, setData] = React.useState(null)

  const [filterExpanded, setFilterExpanded] = React.useState(true)

  const [invitationSent, setInvitationSent] = React.useState('')
  const [invitationOpened, setInvitationOpened] = React.useState('')
  const [invitationStatus, setInvitationStatus] = React.useState('')

  const ClearGuestsFilter = () => {
    setInvitationSent('')
    setInvitationOpened('')
    setInvitationStatus('')
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    window.history.pushState({ path: newurl }, '', newurl)
  }

  const ApplyGuestsFilter = () => {
    const body = {
      sent: invitationSent,
      opened: invitationOpened,
      decision: invitationStatus,
    }
    if (body.sent === '') {
      delete body.sent
    }
    if (body.opened === '') {
      delete body.opened
    }
    if (body.decision === '') {
      delete body.decision
    }

    setQuery({ ...query, ...body, page: 1 })

    let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    newurl += `?page=1`
    Object.keys(body).forEach((filter) => {
      if (body[filter] === false) newurl += `&${filter}=${0}`
      else if (body[filter] === true) newurl += `&${filter}=${1}`
      else newurl += `&${filter}=${body[filter]}`
    })
    window.history.pushState({ path: newurl }, '', newurl)

    props.findGuests({
      event: props.event._id,
      page: 1,
      limit: 10,
      sort: '-createdAt',
      ...body,
    })
  }

  // charts

  const [dataInvitationViewership, setDataInvitationViewership] =
    React.useState([])
  const [configInvitationViewership] = React.useState({
    forceFit: true,
    title: {
      visible: true,
      text: 'Invitation viewership',
    },
    description: {
      visible: true,
      text: 'Viewed invitations vs. Not viewed invitations',
    },
    radius: 0.8,
    data: [],
    angleField: 'value',
    colorField: 'type',
    label: {
      visible: true,
      type: 'spider',
    },
  })

  const [dataInvitationResponse, setDataInvitationResponse] = React.useState([])
  const [configInvitationResponse] = React.useState({
    forceFit: true,
    title: {
      visible: true,
      text: 'Invitation response',
    },
    description: {
      visible: true,
      text: 'Confirmed invitations vs. declined invitations vs. pending invitations',
    },
    radius: 0.8,
    data: [],
    angleField: 'value',
    colorField: 'type',
    label: {
      visible: true,
      type: 'spider',
    },
  })

  const [dataConfirmationMethod, setDataConfirmationMethod] = React.useState([])
  const [configConfirmationMethod] = React.useState({
    forceFit: true,
    title: {
      visible: true,
      text: 'Confirmation method',
    },
    description: {
      visible: true,
      text: 'Confirmed using QR code vs. confirmed through system vs. confirmed manually',
    },
    radius: 0.8,
    data: [],
    angleField: 'value',
    colorField: 'type',
    label: {
      visible: true,
      type: 'spider',
    },
  })

  const [dataAttendancePercentage, setDataAttendancePercentage] =
    React.useState([])
  const [configAttendancePercentage] = React.useState({
    forceFit: true,
    title: {
      visible: true,
      text: 'Attendance percentage',
    },
    description: {
      visible: true,
      text: 'Confirmed attendance vs. unattended',
    },
    radius: 0.8,
    data: [],
    angleField: 'value',
    colorField: 'type',
    label: {
      visible: true,
      type: 'spider',
    },
  })

  const [dataActualAttendance, setDataActualAttendance] = React.useState([])
  const [configActualAttendance] = React.useState({
    forceFit: true,
    title: {
      visible: true,
      text: 'Attendance percentage',
    },
    description: {
      visible: true,
      text: 'Confirmed attendance vs. unattended',
    },
    radius: 0.8,
    data: [],
    angleField: 'value',
    colorField: 'type',
    label: {
      visible: true,
      type: 'spider',
    },
  })

  const getTextFix = (text) => {
    switch (text) {
      case 'confirmedthrough':
        return 'Confirmed Through'
      case 'firstname':
        return 'First Name'
      case 'lastname':
        return 'Last Name'
      case 'phonenumber':
        return 'Phone Number'
      case 'allowedEntries':
        return 'Allowed Entries'

      default:
        return text
    }
  }

  // to remove/add extra vars remove/add them in the extras array

  const generateInfoPopUp = (res) => {
    // const extras = [
    //   'confirmed',
    //   'deleted',
    //   '_id',
    //   'event',
    //   'date',
    //   'createdAt',
    //   'updatedAt',
    //   'guestId',
    //   '__v',
    //   'opened',
    // ]
    const extras = [
      'firstname',
      'lastname',
      'company',
      'job',
      'email',
      'phonenumber',
      'seating',
      'entries',
      'allowedEntries'
    ]
    swal(
      <div>
        <h1>Invitation Information</h1>
        {res?.data ? (
          Object.keys(res?.data).map((el) => {
            console.log("KEY", el)
            if (extras.includes(`${el}`)) {
              console.log("FOUND")
              return (
                <p>
                  <b style={{ textTransform: 'capitalize' }}>
                    {getTextFix(`${el}`)}
                  </b>{' '}
                  {`${res?.data[el]}`}
                </p>
              )
            } else return null;
          })
        ) : (
          <></>
        )}
        {
          res?.data?.limitReached ? (
            <h3 style={{ color: 'red' }}>Limit of entries has been reached for this guest</h3>
          ) : (<></>)
        }
      </div>
    )
  }

  // name, company name, date, venue and description.
  // const updateItemForm = [
  //   {
  //     name: 'name',
  //     label: 'Name',
  //     type: 'text',
  //     value: props.event?.name,
  //     required: true,
  //   },
  //   {
  //     name: 'company',
  //     label: 'Company Name',
  //     type: 'text',
  //     value: props.event?.company,
  //     required: true,
  //   },
  //   {
  //     name: 'date',
  //     label: 'Date',
  //     type: 'date',
  //     value: props.event?.date?.split('T')[0],
  //     required: true,
  //   },
  //   {
  //     name: 'startDate',
  //     label: 'Start Time',
  //     type: 'time',
  //     value: '',
  //     required: true,
  //   },
  //   {
  //     name: 'endDate',
  //     label: 'End Time',
  //     type: 'time',
  //     value: '18:53',
  //     required: true,
  //   },
  //   {
  //     name: 'image',
  //     label: 'Invitation background image',
  //     type: 'image',
  //     value: props.event?.image,
  //     required: false,
  //   },
  //   {
  //     name: 'venue',
  //     label: 'Venue',
  //     type: 'text',
  //     value: props.event?.venue,
  //     required: true,
  //   },
  //   {
  //     name: 'tagsPrinted',
  //     label: 'Print Tags',
  //     type: 'boolean',
  //     value: props.event?.tagsPrinted,
  //     required: true,
  //   },
  //   {
  //     name: 'jobTitleBePrinted',
  //     label: 'Print Job Title',
  //     type: 'boolean',
  //     value: props.event?.jobTitleBePrinted,
  //     required: true,
  //   },
  //   {
  //     name: 'companyNameBePrinted',
  //     label: 'Print Company Name',
  //     type: 'boolean',
  //     value: props.event?.companyNameBePrinted,
  //     required: true,
  //   },
  //   {
  //     name: 'ended',
  //     label: 'Event Ended',
  //     type: 'boolean',
  //     value: props.event?.ended,
  //     required: true,
  //   },
  //   {
  //     name: 'description',
  //     label: 'Description',
  //     type: 'textArea',
  //     value: props.event?.description,
  //     required: true,
  //   },
  // ]

  const addGuestForm = [
    {
      name: 'firstname',
      label: 'First Name',
      type: 'text',
      required: true,
    },
    {
      name: 'lastname',
      label: 'Last Name',
      type: 'text',
      required: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      required: true,
    },
    {
      name: 'phonenumber',
      label: 'Phone Number',
      type: 'phoneNumber',
      required: true,
    },
    {
      name: 'date',
      label: 'Date',
      type: 'date',
      required: true,
      value: props.event?.date?.split('T')[0],
    },
  ]

  const submit = (args, type = submitType) => {
    switch (type) {
      case 'editEvent':
        if(args.startDate)
        args.startDate = new Date(
          2020,
          1,
          1,
          args.startDate.split(':')[0],
          args.startDate.split(':')[1],
          0
        )
        if (args.endDate)
          args.endDate = new Date(
            2020,
            1,
            1,
            args.endDate.split(':')[0],
            args.endDate.split(':')[1],
            0
          )
        const { image, headerImage, footerImage } = args
        delete args.image
        delete args.headerImage
        delete args.footerImage
        props.updateEvent({
          _id: props.event._id,
          ...args,
        })
        if (image) {
          const data = new FormData()
          data.append('event', props.event._id)
          data.append('image', image)
          props.uploadEventImage(data)
        }
        if (headerImage) {
          const data = new FormData()
          data.append('event', props.event._id)
          data.append('image', headerImage)
          props.uploadEventHeaderImage(data)
        }
        if (footerImage) {
          const data = new FormData()
          data.append('event', props.event._id)
          data.append('image', footerImage)
          props.uploadEventFooterImage(data)
        }
        break

      case 'addGuest':
        props
          .addGuest({
            event: props.event._id,
            ...args,
          })
          .then(() => {
            props.findGuests({
              event: props.event._id,
              page: 1,
              limit: 10,
              sort: '-createdAt',
            })
          })
        break

      case 'addConfirmedGuest':
        props
          .addGuest({
            event: props.event._id,
            ...args,
          })
          .then((res) => {
            props
              .updateGuest({
                _id: res.data._id,
                confirmed: true,
                confirmedthrough: 'Manually',
              })
              .then((res) => {
                setInvitationNameTag(
                  `${res?.data?.firstname} ${res?.data?.lastname}`
                )
                setInvitationCompoanyName(res?.data?.company)
                setInvitationJobTitle(res?.data?.job)
                setInvitationSeatingNumber(res?.data?.seating)
                setPrintNames(res?.data?.event?.tagsPrinted)
                setPrintCompanyName(res?.data?.event?.companyNameBePrinted)
                setPrintJobTitle(res?.data?.event?.jobTitleBePrinted)
                setPrintSeatingNumber(res?.data?.event?.seatingNumberBePrinted)
                generateInfoPopUp(res)
                handlePrint()
                props.findGuests({
                  event: props.event._id,
                  page: 1,
                  limit: 10,
                  sort: '-createdAt',
                })
              })
          })
        break

      case 'addGuestList':
        setTimeout(() => {
          props.findGuests({
            event: props.event._id,
            page: 1,
            limit: 10,
            sort: '-createdAt',
          })
        }, 1500)
        break
      case 'sendEmails':
        props.sendEmails({ event: props.event._id, ...args })
        break
      case 'sendSMS':
        props.sendSMS({ event: props.event._id, ...args })
        setRefresh(true);
        break
      case 'sendWhatsapp':
        props.sendWhatsapp({ event: props.event._id, ...args })
        break
      case 'createInvitations':
        props.craeteInvitations({ event: props.event._id }).then(() => {
          props.findEvent({ _id: params.id })
        })
        break
      default:
        break
    }
  }

  const handleScan = (data) => {
    if (data) {
      setOpen(false)
      props
        .updateGuest({ _id: data, confirmed: true, confirmedthrough: 'QR' })
        .then((res) => {
          console.log("RESPONSE RECEIVED", res)
          setInvitationNameTag(`${res?.data?.firstname} ${res?.data?.lastname}`)
          setInvitationCompoanyName(res?.data?.company)
          setInvitationJobTitle(res?.data?.job)
          setInvitationSeatingNumber(res?.data?.seating)
          setPrintNames(res?.data?.event?.tagsPrinted)
          setPrintCompanyName(res?.data?.event?.companyNameBePrinted)
          setPrintJobTitle(res?.data?.event?.jobTitleBePrinted)
          setPrintSeatingNumber(res?.data?.event?.seatingNumberBePrinted)
          generateInfoPopUp(res)
          handlePrint()
          props.findGuests({
            event: props.event._id,
            page: 1,
            limit: 10,
            sort: '-createdAt',
          })
        })
    }
  }

  const handleError = () => { }

  const getStartTime = () => {
    let hours = `${new Date(props.event?.startDate).getHours()}`
    if (hours.length === 1) {
      hours = `0${hours}`
    }

    let minutes = `${new Date(props.event?.startDate).getMinutes()}`
    if (minutes.length === 1) {
      minutes = `0${minutes}`
    }

    return `${hours}:${minutes}`
  }

  const getEndTime = () => {
    let hours = `${new Date(props.event?.endDate).getHours()}`
    if (hours.length === 1) {
      hours = `0${hours}`
    }

    let minutes = `${new Date(props.event?.endDate).getMinutes()}`
    if (minutes.length === 1) {
      minutes = `0${minutes}`
    }

    return `${hours}:${minutes}`
  }

  const viewEntriesDetails = (input) => {
    let allowedEntries = input.allowedEntries;
    swal(
      <div>
        <h1>Invitation Information</h1>
        input
        <div style={{display:'flex'}}>
          <TextField
        margin="dense"
        label={ 'Allowed Entries*' }
        type="number"
        fullWidth
        key={1}
            defaultValue={input.allowedEntries}
            onChange={(v)=>{allowedEntries = v.currentTarget.value}}
      />
          <ActionButton buttonLabel='Change' onSubmit={() => { props.updateGuestAllowedEntries({ _id: input._id, allowedEntries: allowedEntries }); setRefresh(true); swal.close()}} />
        </div>
        {input.entryDetails?.map(d => <p><b>Time Stamp: </b>{
          moment(d)
        .tz('Africa/Cairo').format('dddd, MMMM Do, YYYY hh:mm A')
        }</p>)}
      </div>
    )
  }

  return (
    <>
      <div className="btnActions">
        <AbstractDialog
          open={open}
          close={() => {
            setOpen(false)
          }}
          title={title}
          data={data}
          inputs={inputs}
          noSubmitBtn={submitType === 'addGuestList'}
          onSubmit={(args) => {
            if (typeof args === 'object') {
              setOpen(false)
              submit(args)
            }
          }}
        />
        {/* {excelData ? <JsonToExcel
          data={excelData.data}
          className={excelData.className}
          filename={excelData.filename}
          fields={excelData.fields}
          style={excelData.style}
          text={excelData.text}
        /> : <></>} */}

        {/* <ReactToPrint
          trigger={() => <button>Print this out!</button>}
          content={() => printComponentRef.current}
        /> */}
        <div style={{ display: 'none' }}>
          {printNames ? (
            <NameTagePrint
              invitationNameTag={invitationNameTag}
              invitationCompoanyName={
                printCompanyName ? invitationCompoanyName : ''
              }
              invitationJobTitle={printJobTitle ? invitationJobTitle : ''}
              invitationSeatingNumber={printSeatingNumber ? invitationSeatingNumber : ''}
              ref={printComponentRef}
            />
          ) : (
            ''
          )}
        </div>

        {/* <Button
          variant="contained"
          color={'primary'}
          onClick={() => {
            setSubmitType('sendEmails')
            submit()
          }}
        >
          Send Emails
        </Button> */}
        <AdminGuard>
          {/* <ActionButton
            buttonLabel="Export Event"
            buttonColor="secondary"
            withForm={false}
            onSubmit={() => {
              props.exportEvent({
                _id: params.id
              })
            }}
          /> */}
          <ActionButton
            buttonLabel="Delete All Guests"
            buttonColor="secondary"
            withForm
            form={{
              title: 'Delete All Guests',
              info: 'Enter the password',
              inputs: [
                {
                  name: 'password',
                  label: 'Password',
                  type: 'text',
                  required: true,
                },
              ],
            }}
            onSubmit={(body) => {
              body.event = params.id
              props.deleteAllGuests(body)
            }}
          />

          <ActionButton
            buttonLabel="Send Emails"
            buttonColor="Primary"
            withForm
            submitNoChange
            form={{
              title: 'Send Email Notifications',
              info: 'Select the filters that will be used to send emails',
              inputs: [
                {
                  name: 'sent',
                  label: 'Sent',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                },
                {
                  name: 'opened',
                  label: 'Opened',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                },
                {
                  name: 'decision',
                  label: 'Decision',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Pending',
                      value: 'Pending',
                    },
                    {
                      label: 'Accepted',
                      value: 'Accepted',
                    },
                    {
                      label: 'Rejected',
                      value: 'Rejected',
                    },
                  ],
                },
              ],
            }}
            onSubmit={(values) => {
              if (values.sent === 'All') delete values.sent
              if (values.opened === 'All') delete values.opened
              if (values.decision === 'All') delete values.decision
              setSubmitType('sendEmails')
              submit(values, 'sendEmails')
            }}
          />

          <ActionButton
            buttonLabel="Send SMS"
            buttonColor="Primary"
            withForm
            submitNoChange
            form={{
              title: 'Send SMS Notifications',
              info: 'Select the filters that will be used to send SMS.\nAvailable fields are: !{first_name}! !{last_name}! !{event_name}! !{event_company}! !{event_date}! !{event_start}! !{event_end}! !{event_venue}!',
              inputs: [
                {
                  name: 'sent',
                  label: 'Sent',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                },
                {
                  name: 'opened',
                  label: 'Opened',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                },
                {
                  name: 'decision',
                  label: 'Decision',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Pending',
                      value: 'Pending',
                    },
                    {
                      label: 'Accepted',
                      value: 'Accepted',
                    },
                    {
                      label: 'Rejected',
                      value: 'Rejected',
                    },
                  ],
                },
                {
                  name: 'message',
                  label: 'Body',
                  type: 'textArea',
                  value:
                    props?.event?.smsMessage ||'Hello !{first_name}! !{last_name}!,\nYou are invited to !{event_name}! by !{event_company}! on !{event_date}! at !{event_start}! till !{event_end}!, \n location at !{event_venue}!.',
                },
              ],
            }}
            onSubmit={(values) => {
              if (values.sent === 'All') delete values.sent
              if (values.opened === 'All') delete values.opened
              if (values.decision === 'All') delete values.decision
              setSubmitType('sendSMS')
              submit(values, 'sendSMS')
            }}
          />

		  <ActionButton
            buttonLabel="Send WhatsApp invitations"
            buttonColor="Primary"
            withForm
            submitNoChange
            form={{
              title: 'Send WhatsApp invitations',
              info: 'Select the filters that will be used to send WhatsApp messages',
              inputs: [
                {
                  name: 'sent',
                  label: 'Sent',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                },
                {
                  name: 'opened',
                  label: 'Opened',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ],
                },
                {
                  name: 'decision',
                  label: 'Decision',
                  type: 'select',
                  options: [
                    {
                      label: 'All',
                      value: 'All',
                    },
                    {
                      label: 'Pending',
                      value: 'Pending',
                    },
                    {
                      label: 'Accepted',
                      value: 'Accepted',
                    },
                    {
                      label: 'Rejected',
                      value: 'Rejected',
                    },
                  ],
                },
              ],
            }}
            onSubmit={(values) => {
              if (values.sent === 'All') delete values.sent
              if (values.opened === 'All') delete values.opened
              if (values.decision === 'All') delete values.decision
              setSubmitType('sendWhatsapp')
              submit(values, 'sendWhatsapp')
            }}
          />
        </AdminGuard>
        <AdminGuard>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSubmitType('createInvitations')
              submit({}, 'createInvitations')
            }}
          >
            Re/Create Invitations
          </Button>
        </AdminGuard>
        {props.event?.files?.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(
                `${axios.defaults.baseURL}/guest/guestListPDFstoZIP/${props?.event?._id}`,
                '_blank'
              )
              // console.log();
            }}
          >
            Export Invitations
          </Button>
        ) : (
          <></>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true)
            setSubmitType('scanInvitation')
            setTitle('Scan Inivitation QR Code')
            setInputs([])
            setData(
              <QrReader
                delay={1000}
                onError={(data) => handleError(data)}
                onScan={(err) => handleScan(err)}
                style={{ width: '100%' }}
              />
            )
          }}
        >
          Scan Inivitation QR Code
        </Button>

        <AdminGuard>
          <ActionButton
            buttonLabel="Update Event"
            buttonColor="Primary"
            withForm
            form={{
              title: 'Update Event',
              info: '',
              inputs: [
                {
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  value: props.event?.name,
                  required: true,
                },
                {
                  name: 'company',
                  label: 'Company Name',
                  type: 'text',
                  value: props.event?.company,
                  required: true,
                },
                {
                  name: 'date',
                  label: 'Date',
                  type: 'date',
                  value: props.event?.date?.split('T')[0],
                  required: true,
                },
                {
                  name: 'dateEnd',
                  label: 'End Date',
                  type: 'date',
                  value: props.event?.dateEnd?.split('T')[0],
                  required: false,
                },
                {
                  name: 'startDate',
                  label: 'Start Time',
                  type: 'time',
                  value: getStartTime(),
                  required: false,
                },
                {
                  name: 'endDate',
                  label: 'End Time',
                  type: 'time',
                  value: getEndTime(),
                  required: false,
                },
                {
                  name: 'image',
                  label: 'Invitation background image',
                  type: 'image',
                  value: props.event?.image,
                  required: false,
                },
                {
                  name: 'headerImage',
                  label: 'Invitation Header Image',
                  type: 'image',
                  value: props.event?.headerImage,
                  required: false,
                },
                {
                  name: 'footerImage',
                  label: 'Invitation Footer Image',
                  type: 'image',
                  value: props.event?.footerImage,
                  required: false,
                },
                {
                  name: 'fontColor',
                  label: 'Invitation font color',
                  type: 'text',
                  value: props.event?.fontColor,
                  required: false,
                },
                {
                  name: 'backgroundColor',
                  label: 'Invitation background color',
                  type: 'text',
                  value: props.event?.backgroundColor,
                  required: false,
                },
                {
                  name: 'venue',
                  label: 'Venue',
                  type: 'text',
                  value: props.event?.venue,
                  required: true,
                },
                {
                  name: 'tagsPrinted',
                  label: 'Print Tags',
                  type: 'boolean',
                  value: props.event?.tagsPrinted,
                  required: true,
                },
                {
                  name: 'attendeeNamePrintedPosition',
                  label: 'Printed Attendee Name Position',
                  type: 'numberCanBeZero',
                  value: props.event?.attendeeNamePrintedPosition != null ? props.event?.attendeeNamePrintedPosition : 0,
                  required: true
                },
                {
                  name: 'attendeeNamePrintedName',
                  label: 'Printed Attendee Name\'s Name',
                  type: 'text',
                  value: props.event?.attendeeNamePrintedName || 'Attendee Name',
                  required: true
                },
                {
                  name: 'companyNameBePrinted',
                  label: 'Print Company Name',
                  type: 'boolean',
                  value: props.event?.companyNameBePrinted,
                  required: true,
                },
                {
                  name: 'companyNameBePrintedPosition',
                  label: 'Printed Company Name Position',
                  type: 'numberCanBeZero',
                  value: props.event?.companyNameBePrintedPosition != null ? props.event?.companyNameBePrintedPosition : 1,
                  required: true
                },
                {
                  name: 'companyNameBePrintedName',
                  label: 'Printed Company Name\'s Name',
                  type: 'text',
                  value: props.event?.companyNameBePrintedName || 'Company',
                  required: true
                },
                {
                  name: 'jobTitleBePrinted',
                  label: 'Print Job Title',
                  type: 'boolean',
                  value: props.event?.jobTitleBePrinted,
                  required: true,
                },
                {
                  name: 'jobTitleBePrintedPosition',
                  label: 'Printed Job Title Position',
                  type: 'numberCanBeZero',
                  value: props.event?.jobTitleBePrintedPosition != null ? props.event?.jobTitleBePrintedPosition : 2,
                  required: true
                },
                {
                  name: 'jobTitleBePrintedName',
                  label: 'Printed Job Title\'s Name',
                  type: 'text',
                  value: props.event?.jobTitleBePrintedName || 'Job',
                  required: true
                },
                {
                  name: 'seatingNumberBePrinted',
                  label: 'Print Seating Number',
                  type: 'boolean',
                  value: props.event?.seatingNumberBePrinted,
                  required: true,
                },
                {
                  name: 'seatingNumberBePrintedPosition',
                  label: 'Printed Seating Number Position',
                  type: 'numberCanBeZero',
                  value: props.event?.seatingNumberBePrintedPosition != null ? props.event?.seatingNumberBePrintedPosition : 3,
                  required: true
                },
                {
                  name: 'seatingNumberBePrintedName',
                  label: 'Printed Seating Number\'s Name',
                  type: 'text',
                  value: props.event?.seatingNumberBePrintedName || 'Seating',
                  required: true
                },
                {
                  name: 'attendeeNameInInvitationsPosition',
                  label: 'Invitation Attendee Name Position',
                  type: 'numberCanBeZero',
                  value: props.event?.attendeeNameInInvitationsPosition != null ? props.event?.attendeeNameInInvitationsPosition : 0,
                  required: true
                },
                {
                  name: 'attendeeNameInInvitationsName',
                  label: 'Invitation Attendee Name\'s Name',
                  type: 'text',
                  value: props.event?.attendeeNameInInvitationsName || 'Attendee Name',
                  required: true
                },
                {
                  name: 'companyNameInInvitations',
                  label: 'Invitation Company Name',
                  type: 'boolean',
                  value: props.event?.companyNameInInvitations,
                  required: true,
                },
                {
                  name: 'companyNameInInvitationsPosition',
                  label: 'Invitation Company Name Position',
                  type: 'numberCanBeZero',
                  value: props.event?.companyNameInInvitationsPosition != null ? props.event?.companyNameInInvitationsPosition  : 2,
                  required:true,
                },
                {
                  name: 'companyNameInInvitationsName',
                  label: 'Invitation Company Name\'s Name',
                  type: 'text',
                  value: props.event?.companyNameInInvitationsName || 'Company',
                  required: true,
                },
                {
                  name: 'jobTitleInInvitations',
                  label: 'Invitation Job Title',
                  type: 'boolean',
                  value: props.event?.jobTitleInInvitations,
                  required: true,
                },
                {
                  name: 'jobTitleInInvitationsPosition',
                  label: 'Invitation Job Title Position',
                  type: 'numberCanBeZero',
                  value: props.event?.jobTitleInInvitationsPosition != null ? props.event?.jobTitleInInvitationsPosition : 3,
                  required:true,
                },
                {
                  name: 'jobTitleInInvitationsName',
                  label: 'Invitation Job Title\'s Name',
                  type: 'text',
                  value: props.event?.jobTitleInInvitationsName || 'Job Title',
                  required: true,
                },
                {
                  name: 'seatingNumberInInvitations',
                  label: 'Invitation Seating Number',
                  type: 'boolean',
                  value: props.event?.seatingNumberInInvitations,
                  required: true,
                },
                {
                  name: 'seatingNumberInInvitationsPosition',
                  label: 'Invitation Seating Number Position',
                  type: 'numberCanBeZero',
                  value: props.event?.seatingNumberInInvitationsPosition != null ? props.event?.seatingNumberInInvitationsPosition : 4,
                  required:true,
                },
                {
                  name: 'seatingNumberInInvitationsName',
                  label: 'Invitation Seating Number\'s Name',
                  type: 'text',
                  value: props.event?.seatingNumberInInvitationsName || 'Seating',
                  required: true,
                },
                {
                  name: 'ended',
                  label: 'Event Ended',
                  type: 'boolean',
                  value: props.event?.ended,
                  required: true,
                },
                {
                  name: 'description',
                  label: 'Description',
                  type: 'textArea',
                  value: props.event?.description,
                  required: true,
                },
                {
                  name: 'textBesideQRCode',
                  label: 'Text Beside QR Code',
                  type: 'textArea',
                  value: props.event?.textBesideQRCode,
                  required: false,
                },
              ],
            }}
            customValidation={(inputs, elements) => {
              let positions = [];
              positions.push(elements[inputs.findIndex(i => i.name === 'seatingNumberBePrintedPosition' )]?.value);
              positions.push(elements[inputs.findIndex(i => i.name === 'companyNameBePrintedPosition')]?.value);
              positions.push(elements[inputs.findIndex(i => i.name === 'jobTitleBePrintedPosition')]?.value);
              positions.push(elements[inputs.findIndex(i => i.name === 'attendeeNamePrintedPosition')]?.value);
              for (let i = 0; i < positions.length; i++){
                if (positions.findIndex(p => p === positions[i]) !== i) {
                  return `Duplicate printed field position: ${positions[i]}`
                }
              }
                
              positions = [];
              positions.push(elements[inputs.findIndex(i => i.name === 'seatingNumberInInvitationsPosition' )]?.value);
              positions.push(elements[inputs.findIndex(i => i.name === 'companyNameInInvitationsPosition')]?.value);
              positions.push(elements[inputs.findIndex(i => i.name === 'jobTitleInInvitationsPosition')]?.value);
              positions.push(elements[inputs.findIndex(i => i.name === 'attendeeNameInInvitationsPosition')]?.value);
              for (let i = 0; i < positions.length; i++){
                if (positions.findIndex(p => p === positions[i]) !== i) {
                  return `Duplicate invitations field position: ${positions[i]}`
                }
              }
              
              return null;
            }}
            onSubmit={(body) => {
              console.log('form submitted', body)
              setSubmitType('editEvent')
              submit(body, 'editEvent')
            }}
          />
          {/* <Button
          variant="contained"
          color={'primary'}
          onClick={() => {
            setOpen(true)
            setSubmitType('editEvent')
            setTitle('Edit Event')
            setInputs(updateItemForm)
          }}
        >
          Update Event
        </Button> */}
        </AdminGuard>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true)
            setSubmitType('addGuest')
            setTitle('Add Guest')
            setInputs(addGuestForm)
          }}
        >
          Add Guest
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true)
            setSubmitType('addConfirmedGuest')
            setTitle('Add Confirmed Guest')
            setInputs(addGuestForm)
          }}
        >
          Add Confirmed Guest
        </Button>
        <AdminGuard>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true)
              setSubmitType('addGuestList')
              setTitle('Upload Guest List')
              setData(<UploadExcel id={params.id} />)
              setInputs([])
            }}
          >
            Upload Guest List
          </Button>

          {props.event.name ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                axios
                  .post('/guest/guestListToExcel', { event: params.id })
                  .then((response) => {
                    // console.log(response)
                    window.open(
                      `https://manage.visiongroupeg.com/api/v1/guest/guestListExcel/${response.data.filename}`
                    )
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }}
            >
              export Guest List
            </Button>
          ) : (
            <></>
          )}
        </AdminGuard>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log({ id: props.event._id, name: props.event.name })
            const path = `/statistics?selectedEvents=${JSON.stringify([
              { id: props.event._id, name: props.event.name },
            ])}`
            console.log(path)
            history.push(path)
          }}
        >
          Compare Event
        </Button>
      </div>
      <Main
        data={props.event}
        notToShow={['cards', 'password', 'updatedAt', 'files']}
      />
      {props.event._id ? (
        <>
          <Accordion
            expanded={filterExpanded}
            onChange={() => setFilterExpanded(!filterExpanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="filters">
                <div className="filter">
                  <Typography>Invitation Sent</Typography>
                  <Select
                    value={invitationSent}
                    onChange={() => setInvitationSent(!invitationSent)}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      width: '100%',
                    }}
                  >
                    <MenuItem value="">Choose Invitation Sent</MenuItem>
                    <MenuItem value>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </div>

                <div className="filter">
                  <Typography>Invitation Opened</Typography>
                  <Select
                    value={invitationOpened}
                    onChange={() => setInvitationOpened(!invitationOpened)}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      width: '100%',
                    }}
                  >
                    <MenuItem value="">Choose Invitation Opened</MenuItem>
                    <MenuItem value>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </div>

                <div className="filter">
                  <Typography>Invitation Status</Typography>
                  <Select
                    value={invitationStatus}
                    onChange={(e) => setInvitationStatus(e.target.value)}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      width: '100%',
                    }}
                  >
                    <MenuItem value="">Choose Invitation Status</MenuItem>
                    <MenuItem value="Accepted">Accpeted</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                  </Select>
                </div>

                <div className="filter" />

                <div className="filter">
                  <button onClick={() => ApplyGuestsFilter()}>
                    Apply Filter
                  </button>
                </div>

                <div className="filter">
                  <button
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                    onClick={() => ClearGuestsFilter()}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <AbstractCollection
            queryParams={{
              page: NumberParam,
              search: StringParam,
              opened: BooleanParam,
              sent: BooleanParam,
              decision: StringParam,
            }}
            title="Guests"
            data={props.guests}
            columns={[
              {
                title: 'Name',
                field: 'name',
                render: FullName(['firstname'], ['lastname']),
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Email',
                field: 'email',
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Phone Number',
                field: 'phonenumber',
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Job Title',
                field: 'job',
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Company Name',
                field: 'company',
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Entries',
                field: 'entries',
                render: Entries(['entries'], ['allowedEntries']),
                customFilterAndSearch: () => {
                  return true
                },
                render: (a) =>
                {
                  return <ActionButton
                    buttonLabel={`${a.entries}/${a.allowedEntries}`}
                            buttonColor="primary"
                    onSubmit={() => {
                      viewEntriesDetails(a)
                    }}
                          />
                }
              },
              {
                title: 'Seating',
                field: 'seating',
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Sent Through',
                field: 'sentThrough',
                render: SendingMethods(['emailed'], ['SMSed'], ['WhatsApped']),
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Opened',
                field: 'opened',
                render: BooleanString(['opened']),
                customFilterAndSearch: () => {
                  return true
                },
              },
              {
                title: 'Decision',
                field: 'decision',
                customFilterAndSearch: () => {
                  return true
                },
              },
              // {
              //   title: 'Confirmed',
              //   field: 'confirmed',
              //   render: BooleanString(['confirmed']),
              //   customFilterAndSearch: () => {
              //     return true
              //   }
              // },
              {
                title: 'Confirmed through',
                field: 'confirmedthrough',
                customFilterAndSearch: () => {
                  return true
                },
              },
              // {
              //   title: 'Status',
              //   field: 'status',
              //   customFilterAndSearch: () => {
              //     return true
              //   }
              // },
            ]}
            actions={[
              {
                admin: true,
                onClick: (event, row) =>
                  props.deleteGuest({ _id: row._id }).then(() =>
                    props.findGuests({
                      event: props.event._id,
                      page: 1,
                      limit: 10,
                      sort: '-createdAt',
                    })
                  ),
                icon: DeleteIcon,
              },
              {
                onClick: (event, row) =>
                  props
                    .updateGuest({
                      _id: row._id,
                      confirmed: true,
                      confirmedthrough: 'System',
                    })
                    .then((res) => {
                      setInvitationNameTag(
                        `${res?.data?.firstname} ${res?.data?.lastname}`
                      )
                      setInvitationCompoanyName(res?.data?.company)
                      setInvitationJobTitle(res?.data?.job)
                      setInvitationSeatingNumber(res?.data?.seating)
                      setPrintNames(res?.data?.event?.tagsPrinted)
                      setPrintCompanyName(
                        res?.data?.event?.companyNameBePrinted
                      )
                      setPrintJobTitle(res?.data?.event?.jobTitleBePrinted)
                      setPrintSeatingNumber(res?.data?.event?.seatingNumberBePrinted)
                      generateInfoPopUp(res)
                      handlePrint()
                      props.findGuests({
                        event: props.event._id,
                        page: 1,
                        limit: 10,
                        sort: '-createdAt',
                      })
                    }),
                icon: DoneOutlineIcon,
              },
              {
                onClick:(e,row) => {
                  window.open(
                    `${axios.defaults.baseURL}/guest/guestPDF/${props?.event?._id}/${row._id}`,
                    '_blank'
                  )
                },
                icon: GetApp,
              }
            ]}
            findAction={props.findGuests}
            actionAdditionalParam={{ event: props.event._id }}
            pages={props.guestPage}
          />
        </>
      ) : (
        <></>
      )}

      <p
        style={{
          marginTop: '100px',
          fontSize: '30px',
          fontWeight: 'bold',
        }}
      >
        {' '}
        Statistics
      </p>
      <div
        className={classes.root}
        style={{
          width: '100%',
          marginTop: '20px',
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Invitation viewership" {...a11yProps(0)} />
            <Tab label="Invitation response" {...a11yProps(1)} />
            <Tab label="Confirmation method" {...a11yProps(2)} />
            <Tab label="Attendance percentage" {...a11yProps(3)} />
            <Tab label="Actual attendance" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PieChart
              {...configInvitationViewership}
              data={dataInvitationViewership}
            />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <PieChart
              {...configInvitationResponse}
              data={dataInvitationResponse}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <PieChart
              {...configConfirmationMethod}
              data={dataConfirmationMethod}
            />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <PieChart
              {...configAttendancePercentage}
              data={dataAttendancePercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <PieChart {...configActualAttendance} data={dataActualAttendance} />
          </TabPanel>
        </SwipeableViews>
      </div>
    </>
  )
}

const mapStateToProps = (store) => {
  return {
    event: store.eventReducer.event,
    guest: store.guestReducer.guest,
    guests: store.guestReducer.guests,
    guestPage: store.guestReducer.pages,
  }
}

const mapDispatchToProps = {
  findEvent,
  uploadEventImage,
  uploadEventHeaderImage,
  uploadEventFooterImage,
  updateEvent,
  findGuest,
  findGuests,
  addGuest,
  deleteGuest,
  updateGuest,
  updateGuestAllowedEntries,
  exportGuestList,
  exportGuestListFile,
  eventStatus,
  sendEmails,
  sendWhatsapp,
  sendSMS,
  craeteInvitations,
  deleteAllGuests,
  exportEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(Event)
