import InputLabel from '@material-ui/core/InputLabel'
import React from 'react'

import VariantRender from './variantRender'

const input = {
  isValid: () => {
    return true
  },
  isEmpty: () => {},
  errorMessage: () => {},
  render: (props) => {
    return (
      <form>
        {/* <FormControl fullWidth> */}
        <InputLabel htmlFor="uncontrolled-native">
          {props.label + (props.required ? '*' : '')}
        </InputLabel>
        <VariantRender />
        {/* </FormControl> */}
      </form>
    )
  },
  getValue: (element) => {
    return element.value
  },
}

export default input
