import React from 'react'

const input = {
  isValid: () => {
    return true
  },
  isEmpty: (element) => {
    return element.value.length === 0
  },
  errorMessage: () => {},
  render: (props) => {
    return (
      <>
        <label htmlFor="cars">
          {props.label + (props.required ? '*' : '')}
        </label>

        <select name="cars" id="cars" multiple>
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="opel">Opel</option>
          <option value="audi">Audi</option>
        </select>
      </>
    )
  },
}

export default input
