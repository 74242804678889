import axios from 'axios'

import { LOGIN_USER, LOGOUT_USER } from '../types'
import reduce from './reduce'

const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return reduce(state, action.data)
    case LOGOUT_USER:
      axios.defaults.headers.Authorization = ``
      return {
        user: null,
        token: null,
        isLoggedIn: false,
      }
    default:
      return state
  }
}
