import { combineReducers } from 'redux'

import employeeReducer from './employeeReducer'
import eventReducer from './eventReducer'
import guestReducer from './guestReducer'
import snackbarReducer from './snackbarReducer'
import userReducer from './userReducer'

export default combineReducers({
  userReducer,
  snackbarReducer,
  employeeReducer,
  eventReducer,
  guestReducer,
})
