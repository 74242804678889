import moment from 'moment'
import React from 'react'

import getNestedObject from '../getNestedObject'

const DateTimeString = (path) => (rowData) => {
  const date = getNestedObject(rowData, path)
  return <p>{moment(date).format('hh:mm DD/MM/YYYY')}</p>
}

export default DateTimeString
