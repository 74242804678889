import React from 'react'
import { connect } from 'react-redux'

import {
  findArchiveEvents,
  findEmployees,
  findEvents,
  restoreEvent,
  updateEmployee,
  archiveEvent,
} from '../../../store/actions'

const SelectRenderHelper = (props) => {
  let options = []
  if (props.path[1])
    switch (props.path[1]) {
      case 'ban':
        options = [
          {
            value: 'true',
            label: 'yes',
          },
          {
            value: 'false',
            label: 'no',
          },
        ]
        break
      case 'eventArchive':
        options = [
          {
            value: 'true',
            label: 'yes',
          },
          {
            value: 'false',
            label: 'no',
          },
        ]
        break
      case 'eventRestore':
        options = [
          {
            value: 'true',
            label: 'yes',
          },
          {
            value: 'false',
            label: 'no',
          },
        ]
        break

      default:
        break
    }

  const submit = (event) => {
    switch (props.path[1]) {
      case 'ban':
        // console.log(event.target.value)
        // console.log(props.rowData);
        props
          .updateEmployee({
            _id: props.rowData._id,
            deleted: event.target.value,
          })
          .then((res) => {
            if (res.statusCode)
              props.findEmployees({
                page: 1,
                limit: 10,
                sort: '-createdAt',
              })
          })
        break
      case 'eventArchive':
        // console.log(event.target.value)
        // console.log(props.rowData);
        props
          .archiveEvent({
            _id: props.rowData._id,
          })
          .then((res) => {
            if (res.statusCode)
              props.findEvents({
                page: 1,
                limit: 10,
                sort: '-createdAt',
              })
          })
        break
      case 'eventRestore':
        props
          .restoreEvent({
            _id: props.rowData._id,
          })
          .then((res) => {
            if (res.statusCode)
              props.findArchiveEvents({
                page: 1,
                limit: 10,
                sort: '-createdAt',
              })
          })
        break

      default:
        break
    }
  }

  return (
    <select
      style={{
        padding: '5px',
        borderRadius: '5px',
      }}
      defaultValue={props.rowData[props.path[0]]}
      value={props.rowData[props.path[0]]}
      onChange={(event) => submit(event)}
    >
      {options.map((el) => (
        <option value={el.value}>{el.label}</option>
      ))}
    </select>
  )
}

const mapDispatchToProps = {
  updateEmployee,
  findEmployees,
  findEvents,
  findArchiveEvents,
  restoreEvent,
  archiveEvent,
}

export default connect(null, mapDispatchToProps)(SelectRenderHelper)
