/**
 * This file is implemented to show orders single component
 *
 * @author Ahmed Samir Morsy
 */

import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { findEmployee } from '../../store/actions'
import Main from './main'

const Employee = (props) => {
  const params = useParams()
  React.useEffect(() => {
    props.findEmployee({ _id: params.id })
  }, [])

  return (
    <Main
      data={props.employee}
      notToShow={['cards', 'password', 'updatedAt']}
    />
  )
}

const mapStateToProps = (store) => {
  return {
    employee: store.employeeReducer.employee,
  }
}

const mapDispatchToProps = {
  findEmployee,
}

export default connect(mapStateToProps, mapDispatchToProps)(Employee)
