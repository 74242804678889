/**
 * This file contains the app routes
 *
 * @author Fady Sameh
 */
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Employees from './collectionComponents/employees'
import Events from './collectionComponents/events'
import AuthRoute from './guards/authRoute'
import Home from './home'
import Register from './register'
import ResetPassword from './resetPassword'
import Employee from './singleComponents/employee'
import Event from './singleComponents/event'
import Profile from './singleComponents/profile'
import Statistics from './statistics/statistics'
// import Invitation from './Invitation'

const Main = () => {
  return (
    <Switch>
      {/* <Route path="/Invitation/:token" component={Invitation} />  */}
      <Route path="/resetPassword/:token" component={ResetPassword} />
      <Route path="/register/:token" component={Register} />
      <AuthRoute path="/profile" component={<Profile />} />
      <AuthRoute path="/home" component={<Home />} />
      <AuthRoute path="/event/:id" component={<Event />} />
      <AuthRoute path="/events" component={<Events />} />
      <AuthRoute path="/statistics" component={<Statistics />} />
      <AuthRoute path="/employee/:id" component={<Employee />} />
      <AuthRoute path="/employees" component={<Employees />} />
      <Redirect to="/home" />
    </Switch>
  )
}

export default Main
