/**
 * This component contains the navbar, drawer and renders all the app content
 *
 * @author Fady Sameh
 */
import { Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HomeIcon from '@material-ui/icons/Home'
import MenuIcon from '@material-ui/icons/Menu'
import PeopleIcon from '@material-ui/icons/People'
import clsx from 'clsx'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { logOut } from '../store/actions'
import AdminGuard from './guards/adminGuard'
import Main from './main'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'hidden',
  },
}))

const Container = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [activeName, setActiveName] = useState(null)

  const openDrawer = () => {
    setOpen(true)
  }
  const closeDrawer = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    setActiveName(null)
  }, [window.location.href])

  /**
   * Find icons here:
   * https://material-ui.com/components/material-icons/
   */
  const drawerItems = [
    {
      name: 'Home',
      route: '/home',
      icon: <HomeIcon />,
    },
    null,
    {
      admin: true,
      name: 'Employees',
      route: '/employees',
      icon: <PeopleIcon />,
    },
    null,
    {
      name: 'Events',
      route: '/events',
      icon: <EventIcon />,
    },
    null,
    {
      name: 'Statistics',
      route: '/statistics',
      icon: <AssessmentIcon />,
    },
  ].map((item, i) =>
    item ? (
      item.admin ? (
        <AdminGuard>
          <ListItem
            button
            key={item.name}
            className={
              activeName === item.name ||
              window.location.href.indexOf(item.route) !== -1
                ? 'activeMenu'
                : ''
            }
            onClick={() => {
              props.history.push(item.route)
              setActiveName(item.name)
            }}
          >
            <Tooltip title={item.name} placement="right">
              <ListItemIcon>{item.icon}</ListItemIcon>
            </Tooltip>
            <ListItemText primary={item.name} />
          </ListItem>
        </AdminGuard>
      ) : (
        <ListItem
          button
          key={item.name}
          className={
            activeName === item.name ||
            window.location.href.indexOf(item.route) !== -1
              ? 'activeMenu'
              : ''
          }
          onClick={() => {
            props.history.push(item.route)
            setActiveName(item.name)
          }}
        >
          <Tooltip title={item.name} placement="right">
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.name} />
        </ListItem>
      )
    ) : (
      <Divider key={i} />
    )
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className="spread menu">
            <Typography variant="h6" noWrap>
              Vision Panel
            </Typography>
            <div className="actions">
              <Link to="/profile">
                <AccountCircleIcon />
              </Link>
              <Link onClick={() => props.logOut()}>
                <ExitToAppIcon />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={closeDrawer}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{drawerItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Main />
      </main>
    </div>
  )
}

// const mapStateToProps = store => {
//   return {
//     appointment: store.appointmentReducer.appointment
//   }
// }

const mapDispatchToProps = {
  logOut,
}

export default connect(null, mapDispatchToProps)(withRouter(Container))
