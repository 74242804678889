import { TextField } from '@material-ui/core'
import NativeSelect from '@material-ui/core/NativeSelect'
import React from 'react'

const VariantRender = (props) => {
  const [variants, setVariants] = React.useState([
    { label: '', value: '', disabled: false },
  ])
  const [addedVariants, setAddedVariants] = React.useState([])
  const [price, setPrice] = React.useState(1)
  const variant = [
    'size',
    'connectivity',
    'caseMaterial',
    'caseColor',
    'band',
    'color',
    'technology',
    'graphicsCard',
    'storage',
    'memory',
    'accessories',
    'chargingCase',
    'specifications',
  ]
  const specs = [
    'screen',
    'graphics',
    'sensitivity',
    'battery',
    'processor',
    'ports',
    'description',
    'others',
    'camera',
    'compatibility',
    'connection',
    'material',
    'video',
    'waterResistance',
    'siriRemote',
    'systemRequirement',
    'videoFormats',
    'audioFormats',
    'photoFormats',
    'accessibility',
    'length',
    'diameter',
    'audioTechnology',
    'audioSource',
    'audioFormat',
    'touchSurface',
    'wireless',
    'electricalEnvironmentalRequirement',
    'color',
    'sensors',
    'chip',
    'controls',
    'chargingCase',
    'connectivity',
  ]
  React.useEffect(() => {
    // console.log(props.varaints)
    if (props.varaints && props.varaints.length > 0) {
      const variantss = []
      const originalVar = [...props.varaints]
      Object.keys(originalVar[0]).forEach((vari) => {
        if (variant.includes(vari)) variantss.push(vari)
      })
      setAddedVariants(variantss)
    } else {
      const v = []
      variant.map((el) =>
        v.push({
          label: el,
          value: el,
          disabled: false,
        })
      )
      setVariants(v)
    }
  }, [])

  const renderVariant = (varaint, index) => {
    switch (varaint) {
      case 'specifications':
        return (
          <>
            <p>Specifications </p>
            {props.varaints && props.varaints.length > 0 ? (
              <></>
            ) : (
              <div className="btnActions formBtn">
                <button
                  type="button"
                  onClick={() => {
                    const choosenVariant = varaint
                    const oldVariants = [...variants]
                    oldVariants.forEach((el) => {
                      if (el.label === choosenVariant) el.disabled = false
                    })
                    setVariants(oldVariants)
                    let oldAddedVariants = [...addedVariants]
                    oldAddedVariants = oldAddedVariants.filter(
                      (e) => e !== choosenVariant
                    )
                    setAddedVariants(oldAddedVariants)
                  }}
                >
                  Remove specifications
                </button>
              </div>
            )}
            <div className="indent">
              {specs.map((spec, index) => (
                <TextField
                  defaultValue={
                    props.varaints[props.varaints.length - 1]?.specifications[
                      spec
                    ]
                  }
                  margin="dense"
                  label={`${spec}`}
                  type="text"
                  fullWidth
                  key={index}
                  autoFocus={index === 0}
                  name={`${varaint}-${spec}`}
                />
              ))}
            </div>
          </>
        )
      default:
        return (
          <>
            {props.varaints && props.varaints.length > 0 ? (
              <></>
            ) : (
              <div className="btnActions formBtn">
                <button
                  type="button"
                  onClick={() => {
                    const choosenVariant = varaint
                    const oldVariants = [...variants]
                    oldVariants.forEach((el) => {
                      if (el.label === choosenVariant) el.disabled = false
                    })
                    setVariants(oldVariants)
                    let oldAddedVariants = [...addedVariants]
                    oldAddedVariants = oldAddedVariants.filter(
                      (e) => e !== choosenVariant
                    )
                    setAddedVariants(oldAddedVariants)
                  }}
                >
                  Remove {varaint}
                </button>
              </div>
            )}
            <TextField
              margin="dense"
              label={`${varaint}*`}
              type="text"
              fullWidth
              key={index}
              autoFocus={index === 0}
              name={varaint}
            />
          </>
        )
    }
  }

  return (
    <>
      {props.varaints && props.varaints.length > 0 ? (
        <>
          {addedVariants.map((varaint, index) => renderVariant(varaint, index))}
        </>
      ) : (
        <>
          <NativeSelect
            fullWidth
            defaultValue={variants[0].value}
            inputProps={{
              name: 'name',
              id: 'uncontrolled-native',
            }}
            onChange={(event) => {
              const choosenVariant = event.target.value
              const oldVariants = [...variants]
              oldVariants.forEach((el) => {
                if (el.label === choosenVariant) el.disabled = true
              })
              setVariants(oldVariants)
              const oldAddedVariants = [...addedVariants]
              oldAddedVariants.push(choosenVariant)
              setAddedVariants(oldAddedVariants)
            }}
          >
            {variants.map((option) => (
              <option value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </NativeSelect>
          {addedVariants.map((varaint, index) => renderVariant(varaint, index))}
        </>
      )}
      <TextField
        margin="dense"
        label="price*"
        type="number"
        fullWidth
        name="price"
        value={price}
        onChange={(event) => {
          setPrice(event.target.value > 0 ? event.target.value : 1)
        }}
      />
    </>
  )
}

export default VariantRender
