/**
 * The purpose of guard is to dispaly content base on an arbitrary condition
 *
 * @author Fady Sameh
 */

const Guard = (props) => {
  return props.condition ? props.children : null
}

export default Guard
