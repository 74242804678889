import { FIND_EMPLOYEE, FIND_EMPLOYEES, Update_EMPLOYEE } from '../types'
import { action, noDisaptchAction } from './factory'

const modelName = 'employee'
const routeName = 'employee'

export const findEmployees = action(
  FIND_EMPLOYEES,
  `/${routeName}/readAll`,
  `${modelName}s`
)

export const findEmployee = action(
  FIND_EMPLOYEE,
  `/${routeName}/readOne`,
  `${modelName}`
)

export const updateEmployee = action(
  Update_EMPLOYEE,
  `/${routeName}/update`,
  `${modelName}`
)

export const addEmployee = noDisaptchAction(
  '/employee/invite',
  true,
  () => 'Invitation sent!',
  () => 'An error has occured'
)
