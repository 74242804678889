/**
 * Display content only if user is unauthenticated
 *
 * @author Fady Sameh
 */
import React from 'react'
import { connect } from 'react-redux'

import Guard from './guard'

const UnauthGuard = (props) => {
  return <Guard condition={!props.isLoggedIn}>{props.children}</Guard>
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.userReducer.isLoggedIn,
  }
}

export default connect(mapStateToProps)(UnauthGuard)
