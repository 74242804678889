import React from 'react'

import getNestedObject from '../getNestedObject'

const FullName = (firstNamePath, lastNamePath) => (rowData) => {
  const firstName = getNestedObject(rowData, firstNamePath)
  const lastName = getNestedObject(rowData, lastNamePath)
  return <p>{`${firstName} ${lastName}`}</p>
}

export default FullName
