import React from 'react'

import getNestedObject from '../getNestedObject'

const Entries = (entriesPath, allowedEntriesPath) => (rowData) => {
    const entries = getNestedObject(rowData, entriesPath) || 0
    const allowedEntries = getNestedObject(rowData, allowedEntriesPath) || 0
    return <p>{`${entries}/${allowedEntries}`}</p>
}

export default Entries
