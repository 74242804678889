import Button from '@material-ui/core/Button'
import React, { useState } from 'react'

import AbstractDialog from '../dialog/abstractDialog'
import Guard from '../guards/guard'

const ActionButton = (props) => {
  const [open, setOpen] = useState(false)
  const { withForm } = props

  const onClick = () => {
    if (withForm) {
      setOpen(true)
    } else {
      submit()
    }
  }

  const submit = (args) => {
    props.onSubmit(args)
  }

  return (
    <>
      <Button variant="contained" color={props.buttonColor} onClick={onClick}>
        {props.buttonLabel}
      </Button>
      <Guard condition={withForm}>
        <AbstractDialog
          open={open}
          close={() => setOpen(false)}
          title={props?.form?.title || ''}
          info={props?.form?.info || ''}
          inputs={props?.form?.inputs || []}
          submitNoChange={props?.submitNoChange}
          customValidation={props?.customValidation}
          onSubmit={(args) => {
            setOpen(false)
            submit(args)
          }}
        />
      </Guard>
    </>
  )
}

export default ActionButton
