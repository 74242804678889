export const FIND_EVENTS = 'FIND_EVENTS'
export const FIND_EVENT = 'FIND_EVENT'
export const ADD_EVENT = 'ADD_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'

export const FIND_ARCHIVE_EVENTS = 'FIND_ARCHIVE_EVENTS'
export const FIND_ARCHIVE_EVENT = 'FIND_ARCHIVE_EVENT'
export const RESTORE_EVENT = 'RESTORE_EVENT'
export const ARCHIVE_EVENT = 'ARCHIVE_EVENT'

export const EVENT_STATUS = 'EVENT_STATUS'

export const UPLOAD_EVENT_IMAGE = 'UPLOAD_EVENT_IMAGE'
export const UPLOAD_EVENT_HEADER_IMAGE = 'UPLOAD_EVENT_HEADER_IMAGE'
export const UPLOAD_EVENT_FOOTER_IMAGE = 'UPLOAD_EVENT_FOOTER_IMAGE'
export const IMPORT_EVENT = 'IMPORT_EVENT'
